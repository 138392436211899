.password-strength {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 6px;
  font-family: "Inter UI", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  height: 10px; }
  .password-strength .bar {
    transition: all 0.5s ease;
    width: 0%;
    height: 3px;
    border-radius: 2px;
    left: 5px;
    position: absolute;
    top: 0; }
  .password-strength.strength-1 {
    color: #ed382a; }
    .password-strength.strength-1 .bar {
      width: 20%;
      background: #ed382a; }
  .password-strength.strength-2 {
    color: #fabe53; }
    .password-strength.strength-2 .bar {
      width: 40%;
      background: #fabe53; }
  .password-strength.strength-3 {
    color: #44a6d7; }
    .password-strength.strength-3 .bar {
      width: 60%;
      background: #44a6d7; }
  .password-strength.strength-4 {
    color: #41b05e; }
    .password-strength.strength-4 .bar {
      width: 80%;
      background: #41b05e; }
  .password-strength p {
    opacity: 0;
    transition: all 0.5s ease;
    margin: 0;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    position: absolute;
    right: 5px;
    top: -3px; }
  .password-strength.strength-1 p, .password-strength.strength-2 p, .password-strength.strength-3 p, .password-strength.strength-4 p {
    opacity: 1; }
