.add-inputbox-wrapper:after {
  content: " ";
  clear: both;
  display: table; }

.add-inputbox-wrapper .add-inputbox {
  position: relative;
  width: 36%;
  float: left; }
  .add-inputbox-wrapper .add-inputbox input {
    width: 100%;
    box-sizing: border-box;
    padding-right: 55px; }
  .add-inputbox-wrapper .add-inputbox .link {
    font-family: "Neue Hans Kendrick", sans-serif;
    color: #fabe53;
    display: block;
    text-decoration: none;
    position: absolute;
    padding: 5px;
    right: 5px;
    top: 10px;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold; }
    .add-inputbox-wrapper .add-inputbox .link:hover {
      opacity: 0.5; }

.add-inputbox-wrapper .values {
  float: left;
  width: calc(64% - 20px);
  margin: 5px 10px 0; }

.add-inputbox-wrapper .added-value {
  margin: 5px 10px 0 0;
  background-color: #fabe53;
  color: #ffffff;
  display: inline-block;
  padding: 5px 24px 5px 12px;
  border-radius: 15px;
  position: relative;
  font-size: smaller; }
  .add-inputbox-wrapper .added-value .close-icon {
    position: absolute;
    right: 8px;
    top: 5px;
    cursor: pointer;
    transition: all 0.15s ease-out; }
    .add-inputbox-wrapper .added-value .close-icon:hover {
      opacity: 0.5; }
