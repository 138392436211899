.files-list-section .files-list-header {
  justify-content: space-between; }
  .files-list-section .files-list-header .files-search {
    width: 280px; }

.files-list-section .files-list-content {
  padding: 20px 10px 10px; }
  .files-list-section .files-list-content .no-files-message {
    text-align: center;
    font-weight: bold;
    color: #222222; }
  .files-list-section .files-list-content .total-data-info {
    border-bottom: 1px solid #cbccd0;
    display: flex;
    align-items: center;
    padding: 0px 10px 16px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #c4c4c4;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px; }
    .files-list-section .files-list-content .total-data-info span {
      margin-right: 20px; }
      .files-list-section .files-list-content .total-data-info span strong {
        color: #999999; }
  .files-list-section .files-list-content .table .thead .action-column {
    width: 50px; }
  .files-list-section .files-list-content .table .thead .last-modified-column {
    width: 13.5%; }
  .files-list-section .files-list-content .table .thead .rows-column {
    width: 7.5%; }
  .files-list-section .files-list-content .table .thead .size-column {
    width: 11.5%; }
  .files-list-section .files-list-content .table .thead .table-head {
    padding-bottom: 0; }
  .files-list-section .files-list-content .table .tbody .table-row {
    font-size: 14px;
    font-weight: normal; }
    .files-list-section .files-list-content .table .tbody .table-row .table-cell {
      color: #777777;
      padding-top: 17px;
      padding-bottom: 17px; }
      .files-list-section .files-list-content .table .tbody .table-row .table-cell .copy-button {
        margin-left: 3px; }
      .files-list-section .files-list-content .table .tbody .table-row .table-cell.file-name {
        font-size: 16px;
        color: #222222;
        font-weight: bold; }
      .files-list-section .files-list-content .table .tbody .table-row .table-cell .filename-tooltip {
        font-size: 13px;
        max-width: 600px;
        word-break: break-all;
        font-weight: normal;
        padding: 10px; }
      .files-list-section .files-list-content .table .tbody .table-row .table-cell .conf-file-name {
        display: flex;
        align-items: center; }
        .files-list-section .files-list-content .table .tbody .table-row .table-cell .conf-file-name img {
          width: 36px;
          height: 36px;
          margin-right: 5px; }
        .files-list-section .files-list-content .table .tbody .table-row .table-cell .conf-file-name .conf-file-name-wrapper {
          width: 400px;
          word-break: break-word;
          word-wrap: break-word; }
          .files-list-section .files-list-content .table .tbody .table-row .table-cell .conf-file-name .conf-file-name-wrapper .file-name {
            display: block; }
          .files-list-section .files-list-content .table .tbody .table-row .table-cell .conf-file-name .conf-file-name-wrapper .conf-name {
            display: block;
            font-weight: normal;
            color: #999999;
            font-size: 12px;
            margin-top: 4px; }
