.workspaces-dashboard:after {
  display: table;
  content: " ";
  clear: both; }

.workspaces-dashboard .left-column {
  width: 68.34%;
  float: left; }
  .workspaces-dashboard .left-column .no-workspaces-found {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #222222;
    text-align: center; }
  .workspaces-dashboard .left-column .two-cols {
    position: relative; }
    .workspaces-dashboard .left-column .two-cols:after {
      clear: both;
      content: "";
      display: table; }
    .workspaces-dashboard .left-column .two-cols .left-col,
    .workspaces-dashboard .left-column .two-cols .right-col {
      width: calc(50% - 5px);
      float: left; }
      .workspaces-dashboard .left-column .two-cols .left-col .entity-item.compact,
      .workspaces-dashboard .left-column .two-cols .right-col .entity-item.compact {
        width: 100%; }
    .workspaces-dashboard .left-column .two-cols .left-col {
      margin-right: 10px; }

.workspaces-dashboard .right-column {
  width: 30%;
  float: right; }
