.workspaces-activity-drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.5);
  z-index: 2; }

.workspaces-activity-drawer {
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  transition: height 0.15s ease-out;
  display: flex;
  flex-direction: column; }
  .workspaces-activity-drawer.opened {
    transition: height 0.15s ease-in;
    height: 90vh; }
  .workspaces-activity-drawer .drawer-toggle-button-wrapper {
    margin: 0 auto;
    position: relative;
    width: 200px;
    height: 40px;
    flex-shrink: 0;
    top: 1px;
    border-left: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    background: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
    .workspaces-activity-drawer .drawer-toggle-button-wrapper .left-arc-triangle,
    .workspaces-activity-drawer .drawer-toggle-button-wrapper .right-arc-triangle {
      width: 20px;
      height: 20px;
      position: relative;
      overflow: hidden; }
    .workspaces-activity-drawer .drawer-toggle-button-wrapper .left-arc-triangle {
      position: absolute;
      left: -20px;
      top: 20px; }
      .workspaces-activity-drawer .drawer-toggle-button-wrapper .left-arc-triangle:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 200%;
        height: 200%;
        border-radius: 100%;
        box-shadow: 0px 20px 0px 20px #ffffff;
        border: 1px solid #c4c4c4; }
    .workspaces-activity-drawer .drawer-toggle-button-wrapper .right-arc-triangle {
      position: absolute;
      right: -20px;
      top: 20px; }
      .workspaces-activity-drawer .drawer-toggle-button-wrapper .right-arc-triangle:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 200%;
        height: 200%;
        border-radius: 100%;
        box-shadow: 0px 20px 0px 20px #ffffff;
        border: 1px solid #c4c4c4; }
    .workspaces-activity-drawer .drawer-toggle-button-wrapper .drawer-toggle-button {
      display: block;
      margin: 1px auto;
      background: transparent;
      border: 0;
      width: 100%;
      height: 38px;
      font-size: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #c4c4c4;
      font-weight: bold; }
      .workspaces-activity-drawer .drawer-toggle-button-wrapper .drawer-toggle-button .icon {
        margin-right: 4px;
        font-size: 12px; }
      .workspaces-activity-drawer .drawer-toggle-button-wrapper .drawer-toggle-button:focus, .workspaces-activity-drawer .drawer-toggle-button-wrapper .drawer-toggle-button:active, .workspaces-activity-drawer .drawer-toggle-button-wrapper .drawer-toggle-button:hover {
        outline: none;
        background: transparent; }
  .workspaces-activity-drawer .content-wrapper {
    background: #ffffff;
    border-top: 1px solid #c4c4c4;
    flex-grow: 1; }
    .workspaces-activity-drawer .content-wrapper .wrapper {
      width: 1200px; }
    .workspaces-activity-drawer .content-wrapper .workspaces-activity-header {
      margin-top: 50px;
      justify-content: space-between;
      height: 125px; }
      .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .count-chart-wrapper {
        width: 480px;
        height: 85px;
        position: relative;
        flex-shrink: 0; }
      .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"] {
        -webkit-appearance: none;
        margin: 12.5px 0;
        width: 100%; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]:focus {
          outline: none;
          border: 0; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-moz-focus-outer {
          border: 0;
          outline: none; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-webkit-slider-runnable-track {
          width: 100%;
          height: 5px;
          cursor: pointer;
          transition: all 0.2s ease;
          background: transparent;
          border: 0px solid #222222;
          border-radius: 0px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-webkit-slider-thumb {
          position: relative;
          border: 5px solid rgba(255, 255, 255, 0.5);
          height: 25px;
          width: 25px;
          border-radius: 100%;
          background: #fabe53;
          cursor: pointer;
          z-index: 1;
          box-sizing: border-box;
          -webkit-appearance: none;
          margin-top: -10px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]:focus::-webkit-slider-thumb {
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-moz-range-track {
          width: 100%;
          height: 5px;
          cursor: pointer;
          transition: all 0.2s ease;
          background: transparent;
          border: 0px solid #222222;
          border-radius: 0px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-moz-range-thumb {
          position: relative;
          border: 5px solid rgba(255, 255, 255, 0.5);
          height: 25px;
          width: 25px;
          border-radius: 100%;
          background: #fabe53;
          cursor: pointer;
          z-index: 1;
          box-sizing: border-box; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]:focus::-moz-range-thumb {
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-ms-track {
          width: 100%;
          height: 5px;
          cursor: pointer;
          transition: all 0.2s ease;
          background: transparent;
          border-color: transparent;
          border-width: 25px 0;
          color: transparent; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-ms-fill-lower {
          background: rgba(0, 0, 0, 0);
          border: 0px solid #222222;
          border-radius: 0px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-ms-fill-upper {
          background: transparent;
          border: 0px solid #222222;
          border-radius: 0px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]::-ms-thumb {
          position: relative;
          border: 5px solid rgba(255, 255, 255, 0.5);
          height: 25px;
          width: 25px;
          border-radius: 100%;
          background: #fabe53;
          cursor: pointer;
          z-index: 1;
          box-sizing: border-box; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header input[type="range"]:focus::-ms-thumb {
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }
      .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .range {
        box-sizing: border-box;
        position: relative;
        padding: 0 20px;
        width: 260px;
        margin: 0 23px; }
      .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .range-label {
        width: 100%;
        display: block;
        text-align: center;
        font-family: "Inter UI", sans-serif;
        font-size: 11px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.5px;
        color: #999999;
        font-weight: bold; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .range-label span {
          color: #fabe53; }
          .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .range-label span.day {
            color: #999999;
            font-weight: normal;
            margin-left: 5px; }
      .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box {
        position: relative;
        margin: 0 -12.5px;
        height: 30px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .label-left,
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .label-right {
          font-size: 8px;
          font-weight: bold;
          text-align: center;
          color: #222222;
          opacity: 0.5;
          position: absolute;
          bottom: 2px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .label-left {
          left: -16px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .label-right {
          right: -12px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .left-button {
          position: absolute;
          left: -42px;
          top: -3px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .right-button {
          position: absolute;
          top: -3px;
          right: -42px; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box input[type="range"] {
          display: block;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 30px;
          background: transparent;
          position: relative;
          z-index: 1; }
        .workspaces-activity-drawer .content-wrapper .workspaces-activity-header .input-box .bar {
          position: absolute;
          bottom: 50%;
          left: -5px;
          margin: 0 0 -2.5px;
          height: 5px;
          background: #c4c4c4;
          z-index: 0;
          width: 255px;
          border-radius: 5px;
          pointer-events: none; }
    .workspaces-activity-drawer .content-wrapper .gantt-paper {
      max-height: calc(90vh - 290px);
      overflow: auto; }
      .workspaces-activity-drawer .content-wrapper .gantt-paper .no-activity {
        color: #222222;
        text-align: center;
        font-weight: bold; }
