.configuration-detail .enabled-disabled {
  margin-top: -3px; }
  .configuration-detail .enabled-disabled .fieldset {
    height: 40px; }
  .configuration-detail .enabled-disabled input[type="radio"] {
    height: 40px; }
  .configuration-detail .enabled-disabled .switch {
    height: 34px; }

.configuration-detail .configuration-detail-header {
  align-items: flex-start;
  justify-content: space-between; }
  .configuration-detail .configuration-detail-header .configuration-name-form-wrapper {
    margin-right: 50px; }
  .configuration-detail .configuration-detail-header .component {
    display: flex;
    margin-top: 10px; }
    .configuration-detail .configuration-detail-header .component .icon {
      width: 44px;
      height: 44px;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 50%; }
      .configuration-detail .configuration-detail-header .component .icon img {
        width: 100%;
        height: 100%; }
    .configuration-detail .configuration-detail-header .component .desc {
      color: #999999;
      margin-left: 12px; }
      .configuration-detail .configuration-detail-header .component .desc h4 {
        font-size: 16px;
        font-weight: bold;
        margin: 5px 0 0;
        line-height: 19px; }
      .configuration-detail .configuration-detail-header .component .desc h5 {
        margin: 0;
        font-weight: normal;
        line-height: 16px; }
    .configuration-detail .configuration-detail-header .component.deprecated .desc {
      color: #ed382a; }
    .configuration-detail .configuration-detail-header .component.deprecated .tooltip-bubble {
      padding: 10px;
      font-size: 16px;
      font-weight: normal;
      font-family: "Inter UI", sans-serif;
      font-size: 13px; }
    .configuration-detail .configuration-detail-header .component.deprecated .icon {
      border-color: #ed382a;
      background: #facdcb;
      position: relative; }
      .configuration-detail .configuration-detail-header .component.deprecated .icon:after {
        content: "";
        width: 18px;
        height: 18px;
        display: block;
        background: url("../../../../images/exclamation-triangle.png") center/contain no-repeat;
        position: absolute;
        top: 13px;
        left: 14px; }

.configuration-detail .conf-name-component-icon-wrapper {
  display: flex; }

.configuration-detail .above-paper-right .toggle-switch {
  float: left;
  margin-left: 10px;
  margin-right: 20px; }
  .configuration-detail .above-paper-right .toggle-switch .fieldset {
    margin-top: 0;
    float: none; }

.configuration-detail .action-section {
  text-align: right;
  flex-basis: 350px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between; }
  .configuration-detail .action-section .run-button {
    margin-top: 15px; }
