.delete-data-checkbox label.delete-data-checkbox-label {
  font-size: 11px;
  text-align: right;
  color: #222222;
  padding-right: 24px;
  padding-left: 0;
  width: 86px;
  float: right; }
  .delete-data-checkbox label.delete-data-checkbox-label.disabled {
    cursor: not-allowed; }
  .delete-data-checkbox label.delete-data-checkbox-label.cursor-wait {
    cursor: wait; }

.delete-data-checkbox label span.checkmark {
  left: auto;
  right: 0;
  top: 4px; }
