.tag-picker {
  position: relative;
  width: fit-content;
  margin-right: 230px; }
  .tag-picker .add-button {
    background: #f7f8f9; }
    .tag-picker .add-button .plus {
      font-size: 14px;
      margin-right: -2px;
      float: left; }
    .tag-picker .add-button .add-label {
      line-height: 17px;
      margin-left: 8px; }
    .tag-picker .add-button.cursor-wait {
      cursor: wait; }
  .tag-picker .pick-area {
    position: absolute;
    top: 30px;
    left: 0;
    border: 1px solid #c4c4c4;
    padding: 7.5px;
    width: max-content;
    max-width: 260px;
    background: #ffffff;
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
    display: inline-block; }
    .tag-picker .pick-area .tag {
      margin: 2.5px;
      cursor: pointer;
      transition: all 0.15s ease-out; }
    .tag-picker .pick-area .warning-red {
      white-space: pre;
      font-size: 14px;
      color: #ed382a;
      margin: 2.5px; }
    .tag-picker .pick-area.active .tag {
      opacity: 0.5; }
      .tag-picker .pick-area.active .tag.is-hovering {
        opacity: 1; }
