.workspace-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  background-color: #fabe53; }
  .workspace-icon svg {
    margin-left: 2px; }
