.add-entity-user-modal {
  width: 500px !important;
  height: auto;
  max-height: 75vh;
  overflow-y: auto; }
  .add-entity-user-modal .add-entity-user-form .invite-user-list p:last-child {
    margin-bottom: 20px; }
  .add-entity-user-modal .add-entity-user-form .invite-user-list .form-row {
    display: -webkit-flex;
    -webkit-flex-flow: row nowrap;
    display: flex;
    flex-flow: row nowrap;
    margin: 0 0 10px;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
    justify-content: space-between; }
    .add-entity-user-modal .add-entity-user-form .invite-user-list .form-row:last-of-type {
      border: none;
      margin: 0; }
    .add-entity-user-modal .add-entity-user-form .invite-user-list .form-row .user-field {
      width: 280px; }
    .add-entity-user-modal .add-entity-user-form .invite-user-list .form-row .toggle-switch {
      margin-top: 10px; }
    .add-entity-user-modal .add-entity-user-form .invite-user-list .form-row .trash-icon {
      margin-top: 6px; }
    .add-entity-user-modal .add-entity-user-form .invite-user-list .form-row .admin-permission-message {
      position: absolute;
      font-size: 11px;
      font-weight: bold;
      color: #ed382a;
      right: 0;
      bottom: 0;
      margin-bottom: 5px;
      width: 100%;
      text-align: center; }
  .add-entity-user-modal .add-entity-user-form button.invite-more-btn {
    padding: 0;
    margin: 0;
    display: -webkit-flex;
    -webkit-flex-flow: row nowrap;
    display: flex;
    flex-flow: row nowrap;
    height: 20px; }
    .add-entity-user-modal .add-entity-user-form button.invite-more-btn span {
      font-size: 12px;
      letter-spacing: 0.1em;
      font-weight: bold; }
  .add-entity-user-modal .add-entity-user-form .action-buttons {
    margin-top: 30px; }
