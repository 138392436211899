.tag {
  color: #ffffff;
  font-family: "Inter UI", sans-serif;
  font-weight: bold;
  font-size: 9px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative; }
  .tag .close {
    opacity: 0;
    font-size: 13px;
    position: absolute;
    left: 0;
    right: 0;
    top: 4px;
    margin: 0 auto;
    transition: all 0.15s ease-out; }
  .tag.primary {
    background: #fabe53;
    border: 1px solid #fabe53; }
  .tag.clickable {
    transition: all 0.15s ease-out;
    cursor: pointer; }
    .tag.clickable span {
      transition: all 0.15s ease-out; }
    .tag.clickable:hover span {
      opacity: 0.2; }
    .tag.clickable:hover .close {
      opacity: 1; }
  .tag.small {
    height: 24px;
    line-height: 24px;
    padding: 0 8px; }
  .tag.big {
    height: 34px;
    padding: 11px 18px;
    font-size: 11px; }
  .tag.primary {
    background: #fabe53; }
  .tag.blue {
    background: #01579b; }
  .tag.green {
    background: #3aa545; }
  .tag.yellow {
    background: #fbb962; }
  .tag.red {
    background: #cc4545; }
  .tag.light-blue {
    background: #44a6d7; }
  .tag.purple {
    background: #7c2b73; }
  .tag.lighting-yellow {
    background: #f8ac33; }
  .tag.wasabi {
    background: #739943; }
  .tag.medium-aquamarine {
    background: #5fc4be; }
  .tag.persian-blue {
    background: #24299a; }
  .tag.medium-purple {
    background: #8855db; }
  .tag.contessa {
    background: #c07760; }
  .tag.fire-engine-red {
    background: #d81515; }
  .tag.cabaret {
    background: #ce4364; }
  .tag.macaroni-and-cheese {
    background: #ffb681; }
  .tag.broom {
    background: #edce27; }
  .tag.fuego {
    background: #b4d538; }
  .tag.gossamer {
    background: #3d9f99; }
  .tag.silver-tree {
    background: #5fc487; }
  .tag.nepal {
    background: #9fbac0; }
  .tag.gumbo {
    background: #738f84; }
