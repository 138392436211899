.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .modal-wrapper .modal {
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 101;
    padding: 30px; }
    @media screen and (min-width: 576px) {
      .modal-wrapper .modal {
        width: 420px; }
        .modal-wrapper .modal.small {
          width: 320px; } }
    .modal-wrapper .modal header {
      position: relative; }
      .modal-wrapper .modal header h3 {
        font-family: "Inter UI", sans-serif;
        color: #999999;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 15px; }
      .modal-wrapper .modal header .close-button {
        position: absolute;
        top: -8px;
        right: -8px; }
    .modal-wrapper .modal .modal-body .notice {
      margin-bottom: 0;
      color: #ed382a; }
    .modal-wrapper .modal .modal-body .save-reminder {
      padding: 15px 0;
      font-weight: bold;
      text-align: center; }
    .modal-wrapper .modal .modal-body .form-row:last-of-type {
      margin-bottom: 25px; }
    .modal-wrapper .modal .modal-body > p {
      color: #222222;
      font-size: 17px;
      line-height: 25px; }
    .modal-wrapper .modal.modal-list {
      width: 500px !important;
      height: auto;
      max-height: 75vh;
      overflow-y: auto; }
      .modal-wrapper .modal.modal-list .invite-user-list p:last-child {
        margin-bottom: 20px; }
      .modal-wrapper .modal.modal-list .invite-user-list .form-row {
        display: -webkit-flex;
        -webkit-flex-flow: row nowrap;
        display: flex;
        flex-flow: row nowrap;
        margin: 0 0 10px;
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 10px;
        justify-content: space-between; }
        .modal-wrapper .modal.modal-list .invite-user-list .form-row:last-of-type {
          border: none;
          margin: 0; }
        .modal-wrapper .modal.modal-list .invite-user-list .form-row .user-field {
          width: 280px; }
        .modal-wrapper .modal.modal-list .invite-user-list .form-row .toggle-switch {
          margin-top: 10px; }
        .modal-wrapper .modal.modal-list .invite-user-list .form-row .trash-icon {
          height: 44px; }
        .modal-wrapper .modal.modal-list .invite-user-list .form-row .admin-permission-message {
          position: absolute;
          font-size: 11px;
          font-weight: bold;
          color: #ed382a;
          right: 0;
          bottom: 0;
          margin-bottom: 5px;
          width: 100%;
          text-align: center; }
      .modal-wrapper .modal.modal-list button.invite-more-btn {
        padding: 0;
        margin: 0;
        display: -webkit-flex;
        -webkit-flex-flow: row nowrap;
        display: flex;
        flex-flow: row nowrap;
        height: 20px; }
        .modal-wrapper .modal.modal-list button.invite-more-btn span {
          font-size: 12px;
          letter-spacing: 0.1em;
          font-weight: bold; }
      .modal-wrapper .modal.modal-list .action-buttons {
        margin-top: 15px; }
  .modal-wrapper .modal-overlay {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(34, 34, 34, 0.5); }
  .modal-wrapper .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .modal-wrapper .action-buttons .cancel-button {
      margin-left: -11px; }
