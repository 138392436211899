.order-field-wrapper {
  float: left;
  margin-left: 5px;
  margin-top: 4px; }
  .order-field-wrapper input {
    display: none; }
  .order-field-wrapper .order-button {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 14px;
    display: block;
    height: 14px;
    width: 14px;
    color: #222222;
    cursor: pointer;
    transition: all 0.15s ease-out; }
