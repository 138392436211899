.clone-configuration-modal .form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px; }
  .clone-configuration-modal .form-row .workspace-field {
    width: 300px; }

.clone-configuration-modal .current-wrapper label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 6px;
  display: inline-block;
  letter-spacing: 1px;
  color: #222222; }

.clone-configuration-modal .current-wrapper .current {
  display: block; }
  .clone-configuration-modal .current-wrapper .current.primary {
    border: 1px solid #fabe53; }
