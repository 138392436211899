.text-field.tag-field {
  position: relative; }
  .text-field.tag-field input,
  .text-field.tag-field .helper {
    background: transparent;
    font-weight: bold;
    border: 1px dashed #c4c4c4;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "Inter UI", sans-serif;
    color: #222222;
    box-sizing: border-box;
    padding: 7px 18px; }
    .text-field.tag-field input.has-value, .text-field.tag-field input:focus,
    .text-field.tag-field .helper.has-value,
    .text-field.tag-field .helper:focus {
      border: 1px solid #c4c4c4;
      outline: none;
      box-shadow: none; }
  .text-field.tag-field .helper {
    position: absolute;
    visibility: hidden;
    white-space: pre; }
  .text-field.tag-field.primary input {
    background: #fabe53;
    color: #ffffff;
    border-color: #fabe53; }
  .text-field.tag-field.blue input {
    background: #01579b;
    color: #ffffff;
    border-color: #01579b; }
  .text-field.tag-field.green input {
    background: #3aa545;
    color: #ffffff;
    border-color: #3aa545; }
  .text-field.tag-field.yellow input {
    background: #fbb962;
    color: #ffffff;
    border-color: #fbb962; }
  .text-field.tag-field.red input {
    background: #cc4545;
    color: #ffffff;
    border-color: #cc4545; }
  .text-field.tag-field.light-blue input {
    background: #44a6d7;
    color: #ffffff;
    border-color: #44a6d7; }
  .text-field.tag-field.purple input {
    background: #7c2b73;
    color: #ffffff;
    border-color: #7c2b73; }
  .text-field.tag-field.lighting-yellow input {
    background: #f8ac33;
    color: #ffffff;
    border-color: #f8ac33; }
  .text-field.tag-field.wasabi input {
    background: #739943;
    color: #ffffff;
    border-color: #739943; }
  .text-field.tag-field.medium-aquamarine input {
    background: #5fc4be;
    color: #ffffff;
    border-color: #5fc4be; }
  .text-field.tag-field.persian-blue input {
    background: #24299a;
    color: #ffffff;
    border-color: #24299a; }
  .text-field.tag-field.medium-purple input {
    background: #8855db;
    color: #ffffff;
    border-color: #8855db; }
  .text-field.tag-field.contessa input {
    background: #c07760;
    color: #ffffff;
    border-color: #c07760; }
  .text-field.tag-field.fire-engine-red input {
    background: #d81515;
    color: #ffffff;
    border-color: #d81515; }
  .text-field.tag-field.cabaret input {
    background: #ce4364;
    color: #ffffff;
    border-color: #ce4364; }
  .text-field.tag-field.macaroni-and-cheese input {
    background: #ffb681;
    color: #ffffff;
    border-color: #ffb681; }
  .text-field.tag-field.broom input {
    background: #edce27;
    color: #ffffff;
    border-color: #edce27; }
  .text-field.tag-field.fuego input {
    background: #b4d538;
    color: #ffffff;
    border-color: #b4d538; }
  .text-field.tag-field.gossamer input {
    background: #3d9f99;
    color: #ffffff;
    border-color: #3d9f99; }
  .text-field.tag-field.silver-tree input {
    background: #5fc487;
    color: #ffffff;
    border-color: #5fc487; }
  .text-field.tag-field.nepal input {
    background: #9fbac0;
    color: #ffffff;
    border-color: #9fbac0; }
  .text-field.tag-field.gumbo input {
    background: #738f84;
    color: #ffffff;
    border-color: #738f84; }
