.not-found {
  display: flex;
  justify-content: center;
  align-items: center; }
  .not-found .left-side {
    flex-shrink: 0;
    width: 275px;
    margin: 50px 0; }
  .not-found .right-side {
    color: #222222;
    margin-left: 70px; }
    .not-found .right-side h1 {
      font-size: 32px;
      line-height: 38px;
      max-width: 400px;
      margin-bottom: 20px; }
    .not-found .right-side p {
      font-family: "Neue Hans Kendrick", sans-serif;
      font-size: 24px;
      font-weight: bold;
      position: relative;
      margin-left: 40px; }
      .not-found .right-side p.yes {
        color: #fabe53;
        margin-bottom: 16px; }
        .not-found .right-side p.yes:before {
          display: block;
          content: " ";
          width: 28px;
          height: 28px;
          background: #fabe53;
          left: -40px;
          position: absolute;
          border-radius: 4px; }
      .not-found .right-side p.yes-black {
        color: #222222;
        margin-bottom: 0; }
        .not-found .right-side p.yes-black:before {
          display: block;
          content: " ";
          width: 28px;
          height: 28px;
          background: #222222;
          left: -40px;
          position: absolute;
          border-radius: 4px; }
