.tags-and-ordering {
  display: flex;
  align-items: center; }
  .tags-and-ordering .tag-filter {
    display: -webkit-flex;
    -webkit-flex-flow: row nowrap;
    display: flex;
    flex-flow: row nowrap;
    width: 46%;
    flex-shrink: 0; }
    .tags-and-ordering .tag-filter .label-tags {
      display: -webkit-flex;
      -webkit-flex-flow: row nowrap;
      display: flex;
      flex-flow: row nowrap;
      align-items: center; }
      .tags-and-ordering .tag-filter .label-tags .selected-tags {
        text-transform: uppercase;
        color: #999999;
        letter-spacing: 1px;
        font-size: 11px;
        font-weight: bold;
        font-family: "Inter UI", sans-serif;
        padding: 0 8px;
        white-space: pre; }
      .tags-and-ordering .tag-filter .label-tags .selected-tag {
        margin: 2px 5px 2px 0; }
    .tags-and-ordering .tag-filter .tags-wrapper {
      display: flex;
      flex-wrap: wrap; }
      .tags-and-ordering .tag-filter .tags-wrapper .tag-picker {
        margin-right: 0;
        height: 24px;
        margin: 2px 0; }
  .tags-and-ordering .selectboxes {
    display: flex; }
    .tags-and-ordering .selectboxes .vertical-delimiter {
      width: 1px;
      height: 20px;
      background: #c4c4c4;
      margin: 2px 15px; }
