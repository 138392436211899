.data-in-card-header {
  margin-top: 20px;
  justify-content: space-between; }
  .data-in-card-header .right-part {
    display: flex;
    align-items: center; }
    .data-in-card-header .right-part .save-button {
      margin-left: 15px; }

.data-in-card-content .tooltip-bubble {
  padding: 10px;
  max-width: 600px; }

.data-in-card-content .tooltip-content {
  display: flex;
  word-break: break-all; }
  .data-in-card-content .tooltip-content img {
    height: 36px;
    width: 36px;
    flex-shrink: 0;
    margin-right: 10px; }
  .data-in-card-content .tooltip-content h4 {
    font-size: 16px;
    margin: 0 0 2px; }
  .data-in-card-content .tooltip-content p {
    margin: 0; }

.data-in-card-content .input-config {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid #cbccd0; }
  .data-in-card-content .input-config:first-child {
    padding-top: 10px; }
  .data-in-card-content .input-config:last-child {
    margin-bottom: 10px; }
  .data-in-card-content .input-config.no-files:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
  .data-in-card-content .input-config .left-part {
    display: flex;
    align-items: center;
    min-width: 10%; }
    .data-in-card-content .input-config .left-part img {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border: 2px solid transparent;
      border-radius: 20px; }
      .data-in-card-content .input-config .left-part img.finished {
        border-color: #41b05e; }
      .data-in-card-content .input-config .left-part img.waiting, .data-in-card-content .input-config .left-part img.warning {
        border-color: #fabe53; }
      .data-in-card-content .input-config .left-part img.canceled {
        border-color: #494a4a; }
      .data-in-card-content .input-config .left-part img.running {
        border-color: #229ace; }
      .data-in-card-content .input-config .left-part img.error {
        border-color: #ed382a; }
      .data-in-card-content .input-config .left-part img.disabled {
        background-color: #c4c4c4; }
    .data-in-card-content .input-config .left-part h4 {
      font-size: 16px;
      margin: 0 10px;
      word-break: break-word;
      transition: all 150ms ease-out;
      max-width: calc(100% - 46px);
      word-wrap: break-word; }
    .data-in-card-content .input-config .left-part.conf-link {
      text-decoration: none;
      text-transform: none;
      border-radius: 6px;
      margin: 12px 8px -4px -8px;
      padding: 4px 8px;
      transition: all 150ms ease-out; }
      .data-in-card-content .input-config .left-part.conf-link:hover {
        background: rgba(250, 190, 83, 0.1); }
        .data-in-card-content .input-config .left-part.conf-link:hover h4 {
          color: #fabe53; }
  .data-in-card-content .input-config .right-part {
    flex-shrink: 0; }
    .data-in-card-content .input-config .right-part .label {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #999999;
      margin-bottom: 4px;
      text-transform: uppercase; }
    .data-in-card-content .input-config .right-part .filter-item {
      margin-bottom: 10px;
      width: 155px; }
      .data-in-card-content .input-config .right-part .filter-item:last-of-type {
        margin-bottom: 0; }
      .data-in-card-content .input-config .right-part .filter-item .filter-input {
        padding: 9px 15px;
        width: 120px;
        box-sizing: border-box; }
    .data-in-card-content .input-config .right-part .add-more-button {
      text-decoration: underline;
      height: 24px;
      margin-top: 2px;
      margin-left: 5px; }
      .data-in-card-content .input-config .right-part .add-more-button:hover {
        opacity: 1;
        text-decoration: none; }

.data-in-card-content .files {
  padding: 10px;
  position: relative; }
  .data-in-card-content .files a {
    text-decoration: none; }
    .data-in-card-content .files a .in-file-item {
      cursor: pointer;
      font-size: 13px;
      background: #f7f8f9;
      color: #777777;
      line-height: 26px;
      display: table;
      padding: 0 15px;
      border-radius: 14px;
      border: 1px solid #c4c4c4;
      transition: all 0.15s ease-out;
      opacity: 0.5;
      text-transform: none; }
      .data-in-card-content .files a .in-file-item .copy-button {
        margin-left: 3px; }
      .data-in-card-content .files a .in-file-item:hover {
        opacity: 1; }
  .data-in-card-content .files .row {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between; }
    .data-in-card-content .files .row:last-child {
      margin-bottom: 10px; }
    .data-in-card-content .files .row .download-button {
      padding: 4px;
      min-width: auto;
      min-height: auto; }
  .data-in-card-content .files .scrollable {
    padding-bottom: 0; }
    .data-in-card-content .files .scrollable.content {
      max-height: 145px;
      padding-bottom: 22px;
      box-sizing: border-box;
      padding-right: 10px;
      margin-right: -10px;
      overflow: auto; }
      .data-in-card-content .files .scrollable.content:after {
        content: "";
        width: 100%;
        height: 28px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 0; }

.data-in-card-content .download-all {
  padding-top: 12px;
  border-top: 1px solid #cbccd0;
  text-align: center;
  display: flex;
  justify-content: center; }
