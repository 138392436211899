.data-processed ul {
  margin: 5px 0 0;
  padding: 0; }
  .data-processed ul li {
    list-style-type: none;
    display: inline;
    font-size: 14px;
    line-height: 18px; }

.set-schedule {
  background: #ffffff;
  box-sizing: border-box;
  width: 100%; }
  .set-schedule .set-schedule-inner {
    display: flex;
    justify-content: space-between; }
    .set-schedule .set-schedule-inner .info {
      width: 60%;
      flex-shrink: 0; }
      .set-schedule .set-schedule-inner .info .label {
        display: block;
        width: 140px;
        flex-shrink: 0; }
      .set-schedule .set-schedule-inner .info .schedule-message,
      .set-schedule .set-schedule-inner .info .show-more-wrapper {
        font-size: 14px;
        margin: 7px 0 0; }
      .set-schedule .set-schedule-inner .info .schedule-desc-wrapper {
        display: flex; }
      .set-schedule .set-schedule-inner .info .show-more-wrapper {
        margin-left: 4px; }
        .set-schedule .set-schedule-inner .info .show-more-wrapper .show-more {
          color: #fabe53;
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer; }
          .set-schedule .set-schedule-inner .info .show-more-wrapper .show-more:hover {
            text-decoration: none; }
    .set-schedule .set-schedule-inner .next .label {
      margin-bottom: 3px;
      text-align: right;
      margin-right: 0; }
    .set-schedule .set-schedule-inner .next .schedule-item {
      height: 24px;
      box-sizing: border-box;
      line-height: 22px;
      border: 1px solid #c4c4c4;
      display: inline-block;
      vertical-align: middle;
      font-size: 10px;
      margin-left: 5px;
      padding: 0 6px;
      border-radius: 4px; }
      .set-schedule .set-schedule-inner .next .schedule-item:first-of-type {
        margin-left: 0; }

.list-item {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  margin-top: 10px;
  overflow: hidden;
  color: #777777; }
  .list-item .color-border {
    background: #ffffff;
    padding: 5px 10px 0;
    border-top: 3px solid #999999;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .list-item .color-border.finished {
      border-top: 3px solid #41b05e; }
    .list-item .color-border.warning, .list-item .color-border.waiting {
      border-top: 3px solid #fabe53; }
    .list-item .color-border.error {
      border-top: 3px solid #ed382a; }
    .list-item .color-border.canceled {
      border-top: 3px solid #222222; }
    .list-item .color-border.running {
      border-top: 3px solid #229ace; }
  .list-item .label {
    text-transform: uppercase;
    color: #999999;
    letter-spacing: 1px;
    font-weight: bold;
    font-family: "Inter UI", sans-serif;
    font-size: 11px;
    display: block; }
  .list-item .row {
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    padding: 10px;
    box-sizing: border-box; }
    .list-item .row:last-of-type {
      border-bottom: 0; }
  .list-item .status {
    text-align: right;
    flex-shrink: 0; }
    .list-item .status .label {
      margin-bottom: 5px;
      margin-right: 0; }
  .list-item.disabled {
    color: #999999; }
    .list-item.disabled .color-border {
      border-top-color: #c4c4c4; }
    .list-item.disabled .label {
      color: #c4c4c4; }
    .list-item.disabled .item-name a h3 {
      color: #999999; }
    .list-item.disabled .item-name a:hover h3 {
      color: #fabe53; }
    .list-item.disabled .status-element {
      color: #999999; }
      .list-item.disabled .status-element .duration {
        color: #c4c4c4; }
      .list-item.disabled .status-element .left-icon,
      .list-item.disabled .status-element .right-icon {
        background-color: #c4c4c4; }
    .list-item.disabled .item-tags .tag {
      background-color: #c4c4c4; }
    .list-item.disabled .dawg-link {
      color: #c4c4c4; }

.item-name {
  flex-grow: 1;
  padding-left: 8px;
  margin-left: -8px;
  min-width: 10%; }
  .item-name .item-name-wrapper {
    display: flex;
    min-width: 10%;
    word-wrap: break-word;
    flex-direction: column;
    align-items: flex-start; }
  .item-name a {
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    display: block;
    max-width: 100%; }
    .item-name a h3 {
      border-radius: 6px;
      color: #222222;
      font-size: 18px;
      line-height: 24px;
      margin: 3px 0 0 -8px;
      font-family: "Neue Hans Kendrick", sans-serif;
      padding: 4px 8px;
      display: block; }
    .item-name a:hover h3 {
      color: #fabe53;
      background: rgba(250, 190, 83, 0.1);
      transition: all 150ms ease-out; }

.dawg-name {
  text-align: right;
  min-width: 95px; }
  .dawg-name .dawg-link {
    padding-top: 6px;
    padding-bottom: 6px;
    display: inline-block;
    margin-top: 4px;
    white-space: pre; }
  .dawg-name.is-compact {
    background: #ffffff;
    padding-left: 10px;
    padding-right: 10px; }
    .dawg-name.is-compact .dawg-name-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #c4c4c4; }
      .dawg-name.is-compact .dawg-name-content .dawg-link {
        margin-right: 10px;
        margin-top: 7px;
        margin-bottom: 7px; }

.last-run > p,
.author > p,
.disk-space-used > p {
  margin: 7px 0 0;
  font-size: 14px; }

.item-tags {
  background-color: #f7f8f9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  width: calc(100% + 20px);
  margin-left: -10px;
  border-top: 1px solid #c4c4c4; }
  .item-tags .label {
    margin-right: 15px; }
  .item-tags .tag {
    white-space: pre;
    margin: 2px 5px 2px 0; }
    .item-tags .tag:hover {
      cursor: pointer; }

.example-workspaces .example-workspaces-inner {
  margin-bottom: -2.5px; }
  .example-workspaces .example-workspaces-inner .ws.more {
    opacity: 0.6;
    display: inline-block;
    white-space: pre;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    font-size: 9px;
    text-transform: uppercase;
    color: #777777;
    height: 24px;
    padding: 0 5px;
    line-height: 22px;
    margin-right: 5px; }
  .example-workspaces .example-workspaces-inner .workspace-link,
  .example-workspaces .example-workspaces-inner .ws {
    margin: 2.5px 5px 2.5px 0; }

.disk-space-used .total-used {
  display: flex;
  align-items: center; }

.disk-space-used .value {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 3px; }
  .disk-space-used .value.marked {
    color: #ed382a; }
    .disk-space-used .value.marked .icon-wrapper {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ed382a;
      margin-left: 5px;
      border-radius: 9px;
      color: #ffffff;
      font-size: 8px; }
