.search-page .subtitle {
  max-width: 380px; }

.search-page .search-page-header {
  position: relative; }

.search-page .search-page-search-form {
  width: 230px;
  position: absolute;
  left: 50%;
  margin-left: -115px; }

.search-page .no-results-found {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  text-align: center; }

.search-page .configuration-card {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 16px 10px; }
  .search-page .configuration-card .col-2 {
    width: 27.7%;
    flex-shrink: 0;
    margin-left: 10px; }
    .search-page .configuration-card .col-2 .label {
      text-align: right; }
  .search-page .configuration-card .col-1 {
    width: calc(72.3% - 10px);
    flex-shrink: 0; }
    .search-page .configuration-card .col-1 .row {
      display: flex;
      justify-content: space-between; }
      .search-page .configuration-card .col-1 .row.bottom {
        margin-top: 15px;
        border-top: 1px solid #cbccd0;
        padding-top: 15px; }
        .search-page .configuration-card .col-1 .row.bottom .text {
          margin-top: 5px; }
  .search-page .configuration-card .configuration {
    width: 28.4%;
    padding-left: 10px; }
    .search-page .configuration-card .configuration.value {
      display: flex; }
      .search-page .configuration-card .configuration.value a {
        text-decoration: none;
        text-transform: none;
        display: block;
        margin-left: -6px;
        max-width: 100%; }
        .search-page .configuration-card .configuration.value a h3 {
          font-size: 16px;
          line-height: 21px;
          font-family: "Neue Hans Kendrick", sans-serif;
          border-radius: 6px;
          color: #222222;
          padding: 4px 6px;
          display: block;
          margin-bottom: 2px;
          word-break: break-word;
          word-wrap: break-word;
          max-width: 100%; }
        .search-page .configuration-card .configuration.value a:hover h3 {
          color: #fabe53;
          background: rgba(250, 190, 83, 0.1);
          transition: all 150ms ease-out; }
  .search-page .configuration-card .component {
    width: 21.4%;
    flex-shrink: 0; }
  .search-page .configuration-card .author {
    width: 19.5%;
    flex-shrink: 0; }
  .search-page .configuration-card .modified {
    width: 15%;
    text-align: right;
    padding-right: 10px; }
  .search-page .configuration-card .workspace {
    padding-left: 10px; }
  .search-page .configuration-card .label {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #999999;
    display: block; }
  .search-page .configuration-card .text {
    margin: 0;
    font-size: 14px;
    color: #777777; }
  .search-page .configuration-card .component-icon {
    display: flex;
    align-items: center; }
    .search-page .configuration-card .component-icon .icon {
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      margin: -7px 0 -7px -5px; }
      .search-page .configuration-card .component-icon .icon img {
        width: 100%;
        height: 100%; }
    .search-page .configuration-card .component-icon .desc {
      color: #222222;
      margin-left: 2px; }
      .search-page .configuration-card .component-icon .desc h4 {
        font-size: 14px;
        font-weight: bold;
        margin: -1px 0 0;
        line-height: 17px; }
      .search-page .configuration-card .component-icon .desc h5 {
        margin: 0;
        font-weight: normal;
        line-height: 12px; }
    .search-page .configuration-card .component-icon.deprecated .desc {
      color: #ed382a; }
    .search-page .configuration-card .component-icon.deprecated .icon {
      position: relative; }
      .search-page .configuration-card .component-icon.deprecated .icon:after {
        content: "";
        width: 18px;
        height: 18px;
        display: block;
        background: url("../../../images/exclamation-triangle.png") center/contain no-repeat;
        position: absolute;
        top: 12px;
        left: 13px; }
  .search-page .configuration-card .values {
    align-items: center;
    margin-top: 5px; }
  .search-page .configuration-card .result-code {
    margin-top: 7px;
    width: 100%;
    font-family: monospace;
    white-space: pre;
    box-sizing: border-box;
    overflow: auto;
    background-color: #f7f8f9;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    padding: 10px;
    color: #999999;
    font-size: 12px;
    line-height: 14px; }
    .search-page .configuration-card .result-code .index {
      display: inline-block;
      color: #c4c4c4;
      text-align: right;
      margin-right: 10px; }
      .search-page .configuration-card .result-code .index.l-1 {
        width: 8px; }
      .search-page .configuration-card .result-code .index.l-2 {
        width: 15px; }
      .search-page .configuration-card .result-code .index.l-3 {
        width: 22px; }
      .search-page .configuration-card .result-code .index.l-4 {
        width: 29px; }
      .search-page .configuration-card .result-code .index.l-5 {
        width: 36px; }
    .search-page .configuration-card .result-code p {
      margin: 0; }
      .search-page .configuration-card .result-code p.na {
        text-align: center; }
      .search-page .configuration-card .result-code p.marked {
        color: #41b05e;
        font-weight: bold; }

.search-page .loading-indicator {
  margin: 20px auto 0; }
