.data-flow-section .entity-search {
  width: 280px; }

.data-flow-section .preview {
  position: relative;
  z-index: 52; }

.data-flow-section .dag-preview-overlay {
  position: fixed;
  background: rgba(34, 34, 34, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 51; }

.data-flow-section .data-flow-header {
  margin-top: 20px;
  justify-content: space-between; }
  .data-flow-section .data-flow-header .right-part {
    display: flex;
    align-items: center; }
    .data-flow-section .data-flow-header .right-part button {
      margin-left: 10px; }
    .data-flow-section .data-flow-header .right-part p {
      margin: 0;
      color: #999999;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-family: "Inter UI", sans-serif; }

.data-flow-section .data-flow-container {
  height: 580px;
  position: relative; }
