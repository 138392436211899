.admin-tags .admin-tags-header {
  justify-content: space-between; }

.admin-tags .admin-tags-content {
  padding: 20px 20px 40px; }
  .admin-tags .admin-tags-content h4 {
    font-size: 11px;
    text-transform: uppercase;
    color: #999999;
    letter-spacing: 1px;
    margin: 10px 0 15px; }
  .admin-tags .admin-tags-content .tags-list .tag {
    margin-right: 5px;
    margin-bottom: 5px;
    transition: all 0.15s ease-out; }
    .admin-tags .admin-tags-content .tags-list .tag:hover {
      cursor: pointer;
      opacity: 1; }
  .admin-tags .admin-tags-content .tags-list.active .tag {
    opacity: 0.5; }
    .admin-tags .admin-tags-content .tags-list.active .tag.is-hovering {
      opacity: 1; }

.admin-tags .tag-form-wrapper {
  width: calc(100% - 40px);
  margin-left: 20px;
  background: #f7f8f9;
  border: 1px solid #c4c4c4;
  border-top: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05), 0px 3px 6px rgba(0, 0, 0, 0.05) inset;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 30px 8px 30px 20px;
  box-sizing: border-box; }
