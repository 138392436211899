.configuration-detail .two-column-layout {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .configuration-detail .two-column-layout .left-column {
    flex-grow: 1;
    max-width: calc(100% - 480px); }
  .configuration-detail .two-column-layout .right-column {
    width: 460px;
    margin-left: 20px;
    flex-shrink: 0; }

.popper-close {
  pointer-events: none; }

.file-popper {
  padding: 10px; }
