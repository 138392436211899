.checkbox-field {
  position: relative; }
  .checkbox-field label {
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    padding-top: 1px; }
    .checkbox-field label.disabled {
      cursor: not-allowed; }
    .checkbox-field label .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      transition: all 0.15s ease-out;
      text-align: center;
      font-size: 10px;
      padding-top: 2px;
      box-sizing: border-box; }
      .checkbox-field label .checkmark .icon-check {
        color: #ffffff; }
    .checkbox-field label:hover .checkmark {
      border: 1px solid #c4c4c4; }
    .checkbox-field label .checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
      .checkbox-field label .checkbox:checked ~ .checkmark {
        background-color: #fabe53;
        border-color: #fabe53; }
      .checkbox-field label .checkbox:disabled ~ .checkmark {
        opacity: 0.5; }
