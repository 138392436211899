.trash-header {
  display: flex;
  justify-content: space-between; }
  .trash-header .trash-search {
    width: 280px;
    flex-shrink: 0; }
  .trash-header .filter-type label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: "Neue Hans Kendrick", sans-serif;
    color: #999999;
    text-transform: uppercase; }
  .trash-header .filter-type button {
    margin-left: 10px; }
    .trash-header .filter-type button.primary {
      border: 1px solid transparent; }
    .trash-header .filter-type button .icon {
      margin-right: 5px; }

.trash .chip {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 16px; }
  .trash .chip.workspace {
    background-color: #41b05e; }
  .trash .chip.configuration {
    background-color: #229ace; }
  .trash .chip.user {
    background-color: #fabe53; }

.trash th.deleted-date {
  width: 150px; }

.trash th.type-column {
  width: 200px; }

.trash th.author-column {
  width: 220px; }

.trash td {
  padding: 14px 8px; }
  .trash td.first-cell {
    font-size: 16px;
    line-height: 19px; }
  .trash td .configuration-name-wrapper {
    display: flex; }
  .trash td .configuration-name {
    display: flex;
    align-items: center;
    transition: all 0.15s ease-out;
    border-radius: 6px;
    padding: 4px 8px;
    margin: -4px -8px;
    cursor: pointer; }
    .trash td .configuration-name:hover {
      background: rgba(250, 190, 83, 0.1); }
    .trash td .configuration-name img {
      width: 36px;
      height: 36px;
      margin-right: 5px; }
    .trash td .configuration-name .conf-ws-name-wrapper {
      word-break: break-word;
      word-wrap: break-word; }
      .trash td .configuration-name .conf-ws-name-wrapper .conf-name {
        display: block;
        font-size: 14px;
        line-height: 16px; }
      .trash td .configuration-name .conf-ws-name-wrapper .ws-name {
        display: block;
        font-weight: normal;
        color: #999999;
        font-size: 10px;
        margin-top: 0px;
        line-height: 12px; }
  .trash td .username .user-name {
    display: block;
    font-size: 14px;
    line-height: 17px; }
  .trash td .username .user-email {
    display: block;
    font-weight: normal;
    color: #999999;
    font-size: 10px;
    margin-top: 0px;
    line-height: 12px; }
