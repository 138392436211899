.schedule-header .edit-schedule-actions,
.schedule-form-header .edit-schedule-actions {
  display: flex;
  align-items: center; }
  .schedule-header .edit-schedule-actions .delete-entire-schedule,
  .schedule-header .edit-schedule-actions .cancel,
  .schedule-form-header .edit-schedule-actions .delete-entire-schedule,
  .schedule-form-header .edit-schedule-actions .cancel {
    margin-right: 10px; }

.schedule-header {
  justify-content: space-between;
  margin-top: 20px; }

.schedule-form-header {
  justify-content: space-between;
  margin-top: 20px; }
  .schedule-form-header .left-header-part {
    display: flex;
    align-items: center; }
    .schedule-form-header .left-header-part .schedule-type-selection .schedule-variant-button {
      padding: 4px;
      font-weight: bold; }
      .schedule-form-header .left-header-part .schedule-type-selection .schedule-variant-button.active span {
        color: #222222; }

.schedules-info {
  padding: 15px;
  color: #777777;
  font-size: 13px;
  line-height: 19px; }
  .schedules-info .common-schedules-info {
    padding: 15px;
    width: 100%;
    background: #f7f8f9;
    border: 1px solid #c4c4c4;
    border-radius: 8px; }
    .schedules-info .common-schedules-info .next-dates {
      display: inline-block; }
    .schedules-info .common-schedules-info .tooltip {
      padding: 10px;
      text-align: left; }
    .schedules-info .common-schedules-info td {
      vertical-align: top; }
      .schedules-info .common-schedules-info td span {
        display: block; }
      .schedules-info .common-schedules-info td.next-schedules-title {
        font-size: 15px; }
      .schedules-info .common-schedules-info td.align-right {
        text-align: right; }
  .schedules-info .schedules-readable {
    width: 100%;
    color: #777777;
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    justify-content: space-between; }
    .schedules-info .schedules-readable h4 {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
      width: 180px;
      flex-shrink: 0; }
    .schedules-info .schedules-readable p {
      text-align: right; }
    .schedules-info .schedules-readable tr.dashed-bottom {
      border-bottom: 1px dashed #c4c4c4; }
      .schedules-info .schedules-readable tr.dashed-bottom td {
        padding-bottom: 6px; }
      .schedules-info .schedules-readable tr.dashed-bottom:last-child {
        border-bottom: 0; }
        .schedules-info .schedules-readable tr.dashed-bottom:last-child td {
          padding-bottom: 2px; }
    .schedules-info .schedules-readable tr.first-row td {
      padding-top: 6px; }
    .schedules-info .schedules-readable tr.first-row:first-child td {
      padding-top: 2px; }

.schedule-form .tooltip {
  max-width: 320px;
  padding: 10px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400; }

.schedule-form .schedule-topbar {
  padding: 10px 5px 20px 10px;
  border-bottom: 1px solid #c4c4c4; }
  .schedule-form .schedule-topbar .once-repeat-switch {
    float: right;
    margin-bottom: 2.5px; }
    .schedule-form .schedule-topbar .once-repeat-switch .fieldset {
      margin: 0; }
  .schedule-form .schedule-topbar .tabs {
    float: left;
    width: 480px;
    margin-top: -2.5px; }
  .schedule-form .schedule-topbar:after {
    content: " ";
    display: table;
    clear: both; }

.schedule-form .schedule-bottombar {
  padding: 20px 10px 10px; }
  .schedule-form .schedule-bottombar .notify-emails {
    width: 100%; }

.schedule-form .delete-time-button .bin-icon {
  margin-right: 4px; }

.schedule-form .schedule-middlebar {
  padding: 20px 10px;
  border-bottom: 1px solid #c4c4c4;
  position: relative; }
  .schedule-form .schedule-middlebar .days,
  .schedule-form .schedule-middlebar .calendar {
    float: left; }
  .schedule-form .schedule-middlebar .delete-time-section {
    position: absolute;
    right: 5px;
    top: 35px; }
  .schedule-form .schedule-middlebar:after {
    content: " ";
    display: table;
    clear: both; }
  .schedule-form .schedule-middlebar .vertical-line,
  .schedule-form .schedule-middlebar .vertical-line-long {
    float: left;
    width: 1px;
    height: 32px;
    margin: 23px 20px 0 15px;
    background: #c4c4c4; }
  .schedule-form .schedule-middlebar .vertical-line-long {
    height: 168px; }
  .schedule-form .schedule-middlebar .repeat-section {
    float: left; }
    .schedule-form .schedule-middlebar .repeat-section .repeat-row input {
      float: left; }
    .schedule-form .schedule-middlebar .repeat-section .repeat-row:after {
      content: " ";
      display: table;
      clear: both; }
    .schedule-form .schedule-middlebar .repeat-section .offset-field__single-value,
    .schedule-form .schedule-middlebar .repeat-section .offset-field__option {
      font-weight: bold;
      font-size: 13px;
      color: #222222; }
    .schedule-form .schedule-middlebar .repeat-section .offset-field__value-container {
      opacity: 1; }
    .schedule-form .schedule-middlebar .repeat-section .repeat-value {
      width: 90px;
      box-sizing: border-box;
      text-align: center;
      float: left; }
      .schedule-form .schedule-middlebar .repeat-section .repeat-value.starting-at {
        width: 115px;
        margin-left: 0; }
      .schedule-form .schedule-middlebar .repeat-section .repeat-value svg {
        font-size: 12px; }
      .schedule-form .schedule-middlebar .repeat-section .repeat-value .repeat-field__value-container,
      .schedule-form .schedule-middlebar .repeat-section .repeat-value .offset-field__value-container {
        opacity: 1; }
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .repeat-field__value-container .repeat-field__option,
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .repeat-field__value-container .offset-field__option,
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .offset-field__value-container .repeat-field__option,
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .offset-field__value-container .offset-field__option {
          padding-left: 4px;
          margin-left: 2px;
          margin-right: 2px;
          max-width: calc(100% - 8px);
          overflow: hidden;
          position: absolute;
          text-overflow: ellipsis;
          white-space: nowrap;
          top: 50%;
          transform: translateY(-50%);
          box-sizing: border-box; }
      .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-option,
      .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-single-value {
        line-height: 16px;
        justify-content: center; }
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-option .value,
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-single-value .value {
          font-weight: bold;
          font-size: 13px;
          color: #222222; }
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-option .type,
        .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-single-value .type {
          font-size: 10px;
          font-weight: 500;
          opacity: 0.5;
          margin-left: 2px;
          color: #222222; }
      .schedule-form .schedule-middlebar .repeat-section .repeat-value .select-single-value {
        justify-content: flex-start; }
      .schedule-form .schedule-middlebar .repeat-section .repeat-value .repeat-field__option--is-selected .select-option {
        color: #ffffff; }
    .schedule-form .schedule-middlebar .repeat-section .from-time,
    .schedule-form .schedule-middlebar .repeat-section .to-time {
      float: left;
      position: relative;
      margin-left: 10px; }
      .schedule-form .schedule-middlebar .repeat-section .from-time .grey-label,
      .schedule-form .schedule-middlebar .repeat-section .to-time .grey-label {
        position: absolute;
        top: -17px;
        color: #999999;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 0; }
      .schedule-form .schedule-middlebar .repeat-section .from-time input,
      .schedule-form .schedule-middlebar .repeat-section .to-time input {
        width: 50px;
        box-sizing: border-box;
        padding-left: 4px;
        padding-right: 28px;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        color: #222222; }
        .schedule-form .schedule-middlebar .repeat-section .from-time input:disabled:hover,
        .schedule-form .schedule-middlebar .repeat-section .to-time input:disabled:hover {
          cursor: not-allowed; }
      .schedule-form .schedule-middlebar .repeat-section .from-time:after,
      .schedule-form .schedule-middlebar .repeat-section .to-time:after {
        display: block;
        content: "Hour";
        font-size: 10px;
        font-weight: 500;
        color: #222222;
        opacity: 0.5;
        position: absolute;
        right: 4px;
        top: 16px;
        pointer-events: none; }
    .schedule-form .schedule-middlebar .repeat-section .from-time:before {
      content: "-";
      font-size: 13px;
      font-weight: bold;
      line-height: 16px;
      color: #222222;
      position: absolute;
      right: -8px;
      top: 14px;
      display: block; }
    .schedule-form .schedule-middlebar .repeat-section .starting-at {
      float: left;
      margin-left: 10px;
      position: relative; }
      .schedule-form .schedule-middlebar .repeat-section .starting-at .grey-label {
        position: absolute;
        top: -17px; }
      .schedule-form .schedule-middlebar .repeat-section .starting-at .repeat-value {
        float: none; }
      .schedule-form .schedule-middlebar .repeat-section .starting-at.disabled:hover {
        cursor: not-allowed; }

.schedule-form .once-section {
  float: left; }
  .schedule-form .once-section .time-input {
    width: 44px;
    box-sizing: border-box;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px; }

.schedule-form .tabs .tab {
  margin: 2.5px 5px 2.5px 0; }
  .schedule-form .tabs .tab:focus {
    outline: none;
    box-shadow: none; }

.schedule-form .days .button {
  margin-right: 5px;
  font-size: 11px;
  padding-left: 8px;
  padding-right: 8px; }
  .schedule-form .days .button:focus {
    box-shadow: none; }

.schedule-form .calendar {
  width: 336px; }
  .schedule-form .calendar .calendar-button {
    width: 10.8%;
    border: 0;
    color: #777777;
    font-size: 11px;
    letter-spacing: 1px;
    transition: all 0.15s ease-out;
    border-radius: 50%;
    padding: 0;
    margin: 1.7%;
    font-weight: bold; }
    .schedule-form .calendar .calendar-button:hover {
      background: #999999;
      color: #ffffff; }
    .schedule-form .calendar .calendar-button:focus {
      outline: none; }
    .schedule-form .calendar .calendar-button.active {
      background: #fabe53;
      color: #ffffff; }
