.user-settings .user-settings-header {
  display: -webkit-flex;
  -webkit-flex-flow: row nowrap;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  .user-settings .user-settings-header .cancel-button {
    margin-right: 10px; }
  .user-settings .user-settings-header .save-button {
    border: none; }

.user-settings .user-settings-content {
  display: -webkit-flex;
  -webkit-flex-flow: row nowrap;
  display: flex;
  flex-flow: row nowrap; }
  .user-settings .user-settings-content .non-editable-data {
    display: -webkit-flex;
    -webkit-flex-flow: row nowrap;
    display: flex;
    flex-flow: row nowrap;
    width: 50%; }
    .user-settings .user-settings-content .non-editable-data .avatar {
      display: -webkit-flex;
      -webkit-flex-flow: row nowrap;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      padding: 20px;
      width: 40%; }
      .user-settings .user-settings-content .non-editable-data .avatar .img {
        width: 100px;
        height: 100px; }
        .user-settings .user-settings-content .non-editable-data .avatar .img .inicials {
          font-size: 28px; }
    .user-settings .user-settings-content .non-editable-data .non-editable {
      width: 60%; }
  .user-settings .user-settings-content .editable-data {
    width: 50%; }
  .user-settings .user-settings-content .user-data {
    padding: 20px; }
    .user-settings .user-settings-content .user-data .user .user-value {
      color: #999999; }
    .user-settings .user-settings-content .user-data .user .nameTextField {
      width: 50%; }
    .user-settings .user-settings-content .user-data .user.first {
      margin-bottom: 20px; }
    .user-settings .user-settings-content .user-data .edit-password {
      width: 50%; }
    .user-settings .user-settings-content .user-data .user-label {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: bold;
      font-family: "Neue Hans Kendrick", sans-serif; }
