.dropdown {
  position: relative;
  box-sizing: border-box; }
  .dropdown .toggle-button {
    border: 0;
    font-size: 16px;
    color: #777777;
    background: transparent; }
    .dropdown .toggle-button:focus {
      outline: 0; }
  .dropdown .dropdown-content {
    border: 1px solid #c4c4c4;
    padding: 15px;
    position: absolute;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 10px;
    box-sizing: border-box;
    min-width: 240px;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    z-index: 3;
    overflow: hidden;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .dropdown .dropdown-content .dropdown-list {
      margin: 0;
      padding: 0;
      box-sizing: border-box; }
      .dropdown .dropdown-content .dropdown-list li {
        display: inline-block;
        width: calc(100% - 10px);
        white-space: nowrap;
        margin: 7px 0px; }
        .dropdown .dropdown-content .dropdown-list li:first-child {
          margin-top: 0; }
        .dropdown .dropdown-content .dropdown-list li:last-child {
          margin-bottom: 0; }
    .dropdown .dropdown-content a,
    .dropdown .dropdown-content .link-like {
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      color: #777777;
      text-transform: none;
      cursor: pointer; }
      .dropdown .dropdown-content a:hover,
      .dropdown .dropdown-content .link-like:hover {
        color: #fabe53; }
      .dropdown .dropdown-content a.active,
      .dropdown .dropdown-content .link-like.active {
        color: #fabe53; }
      .dropdown .dropdown-content a.is-disabled,
      .dropdown .dropdown-content .link-like.is-disabled {
        color: #999999;
        cursor: not-allowed; }
        .dropdown .dropdown-content a.is-disabled:hover,
        .dropdown .dropdown-content .link-like.is-disabled:hover {
          color: #999999; }
    .dropdown .dropdown-content.right {
      right: -10px; }
