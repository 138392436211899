@import "../../../../../scssShared/variables";

.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.disabled {
  color: $color_warning_red;
  font-size: 12px;
  font-weight: 600;
}
