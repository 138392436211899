.entity-jobs-card-header {
  justify-content: space-between; }

.entity-jobs-card-list {
  padding: 10px; }
  .entity-jobs-card-list .job-item {
    box-sizing: border-box;
    width: 100%;
    transition: all 0.15s ease-out;
    border-radius: 4px;
    cursor: pointer;
    text-transform: none;
    display: block;
    text-decoration: none; }
    .entity-jobs-card-list .job-item:hover {
      background: rgba(250, 190, 83, 0.05); }
    .entity-jobs-card-list .job-item .content-border {
      box-sizing: border-box;
      border-bottom: 1px solid #cbccd0;
      padding: 10px 5px;
      margin: 0px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .entity-jobs-card-list .job-item .content-border .left-part .user {
        margin: 0;
        font-size: 12px;
        line-height: 15px;
        color: #222222;
        font-weight: bold; }
      .entity-jobs-card-list .job-item .content-border .left-part .date-info {
        margin: 0;
        font-size: 10px; }
    .entity-jobs-card-list .job-item:last-child .content-border {
      border-bottom: 0; }
