.settings-form-card-header {
  margin-top: 20px;
  justify-content: space-between; }
  .settings-form-card-header .buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center; }
    .settings-form-card-header .buttons .info-icon {
      font-size: 15px;
      color: #999999;
      margin-right: 5px; }
  .settings-form-card-header .edit-button {
    margin-left: 10px; }
  .settings-form-card-header .cancel-button {
    margin-right: 15px; }
  .settings-form-card-header .component-tooltip {
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    font-family: "Inter UI", sans-serif; }
  .settings-form-card-header .auth-start-buttons {
    display: flex;
    align-items: center; }
    .settings-form-card-header .auth-start-buttons .copy-button {
      margin-left: 10px;
      font-size: 16px; }

.json-form-wrapper {
  padding: 20px;
  position: relative; }
  .json-form-wrapper .form-part-title {
    letter-spacing: 1px;
    font-weight: bold;
    color: #777777;
    text-transform: uppercase;
    font-size: 13px;
    position: relative;
    margin: 0 0 15px; }
  .json-form-wrapper .toggle-form-json {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 20px;
    margin-bottom: 20px; }
    .json-form-wrapper .toggle-form-json h4.form-part-title {
      margin-bottom: 0; }
  .json-form-wrapper .control-buttons {
    display: block;
    margin-top: 30px;
    text-align: center; }
    .json-form-wrapper .control-buttons .submit-button {
      margin-left: 10px; }
    .json-form-wrapper .control-buttons.mb-0 {
      margin-bottom: 0; }
  .json-form-wrapper .json-form .expand-collapse-all-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 11px;
    letter-spacing: 1px;
    z-index: 1; }
    .json-form-wrapper .json-form .expand-collapse-all-wrapper span {
      color: #222222;
      font-family: "Inter UI", sans-serif;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer; }
      .json-form-wrapper .json-form .expand-collapse-all-wrapper span:hover {
        text-decoration: none; }
  .json-form-wrapper .json-form .settings-form p {
    margin: 0; }
  .json-form-wrapper .json-form .settings-form .custom-object-field .title-collapsed:last-of-type {
    margin-bottom: 0; }
  .json-form-wrapper .json-form .settings-form .field-description {
    font-size: 12px;
    margin-top: 5px; }
  .json-form-wrapper .json-form .settings-form .row,
  .json-form-wrapper .json-form .settings-form .field {
    margin: 0; }
    .json-form-wrapper .json-form .settings-form .row .field-error > input,
    .json-form-wrapper .json-form .settings-form .row .field-error > textarea,
    .json-form-wrapper .json-form .settings-form .row .field-error > select,
    .json-form-wrapper .json-form .settings-form .field .field-error > input,
    .json-form-wrapper .json-form .settings-form .field .field-error > textarea,
    .json-form-wrapper .json-form .settings-form .field .field-error > select {
      color: #777777;
      border: 1px solid #ed382a;
      box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25); }
      .json-form-wrapper .json-form .settings-form .row .field-error > input:focus,
      .json-form-wrapper .json-form .settings-form .row .field-error > textarea:focus,
      .json-form-wrapper .json-form .settings-form .row .field-error > select:focus,
      .json-form-wrapper .json-form .settings-form .field .field-error > input:focus,
      .json-form-wrapper .json-form .settings-form .field .field-error > textarea:focus,
      .json-form-wrapper .json-form .settings-form .field .field-error > select:focus {
        border-color: #ed382a;
        box-shadow: 0 1px 3px 0px rgba(237, 56, 42, 0.25); }
    .json-form-wrapper .json-form .settings-form .row .field-error .error-detail,
    .json-form-wrapper .json-form .settings-form .field .field-error .error-detail {
      margin: 4px 0 0 17px;
      padding: 0;
      color: #ed382a;
      list-style-type: none;
      font-size: 11px;
      line-height: 13px;
      font-weight: bold; }
    .json-form-wrapper .json-form .settings-form .row .field-object .chevron,
    .json-form-wrapper .json-form .settings-form .field .field-object .chevron {
      display: none; }
    .json-form-wrapper .json-form .settings-form .row .field-object.collapsable .chevron,
    .json-form-wrapper .json-form .settings-form .field .field-object.collapsable .chevron {
      position: absolute;
      display: block;
      left: 0;
      top: 1px;
      font-size: 12px;
      cursor: pointer; }
    .json-form-wrapper .json-form .settings-form .row .field-object.collapsable .collapsed,
    .json-form-wrapper .json-form .settings-form .field .field-object.collapsable .collapsed {
      display: none; }
    .json-form-wrapper .json-form .settings-form .row .field-object.collapsable .form-part-title,
    .json-form-wrapper .json-form .settings-form .field .field-object.collapsable .form-part-title {
      cursor: pointer;
      padding-left: 18px;
      user-select: none; }
    .json-form-wrapper .json-form .settings-form .row .field-object.not-collapsable .content-padding > .row, .json-form-wrapper .json-form .settings-form .row .field-object.collapsable .content-padding > .row,
    .json-form-wrapper .json-form .settings-form .field .field-object.not-collapsable .content-padding > .row,
    .json-form-wrapper .json-form .settings-form .field .field-object.collapsable .content-padding > .row {
      padding-left: 10px; }
    .json-form-wrapper .json-form .settings-form .row .field-array,
    .json-form-wrapper .json-form .settings-form .field .field-array {
      background: #f7f8f9; }
    .json-form-wrapper .json-form .settings-form .row .custom-array-field,
    .json-form-wrapper .json-form .settings-form .field .custom-array-field {
      padding: 15px;
      border: 1px solid #c4c4c4;
      border-radius: 4px; }
      .json-form-wrapper .json-form .settings-form .row .custom-array-field > div:last-child,
      .json-form-wrapper .json-form .settings-form .field .custom-array-field > div:last-child {
        margin-bottom: 0; }
      .json-form-wrapper .json-form .settings-form .row .custom-array-field .custom-array-field-item,
      .json-form-wrapper .json-form .settings-form .field .custom-array-field .custom-array-field-item {
        position: relative;
        margin-bottom: 15px; }
        .json-form-wrapper .json-form .settings-form .row .custom-array-field .custom-array-field-item .delete-button,
        .json-form-wrapper .json-form .settings-form .field .custom-array-field .custom-array-field-item .delete-button {
          position: absolute;
          top: -10px;
          right: 0; }
      .json-form-wrapper .json-form .settings-form .row .custom-array-field .form-part-title.array-title,
      .json-form-wrapper .json-form .settings-form .field .custom-array-field .form-part-title.array-title {
        padding: 0;
        cursor: default;
        margin: 0 0 15px; }
        .json-form-wrapper .json-form .settings-form .row .custom-array-field .form-part-title.array-title:only-child,
        .json-form-wrapper .json-form .settings-form .field .custom-array-field .form-part-title.array-title:only-child {
          margin: 0; }
  .json-form-wrapper .json-form .settings-form .row .text-field:last-of-type {
    margin-bottom: 0; }
  .json-form-wrapper .json-form .settings-form .row .text-field .field-string,
  .json-form-wrapper .json-form .settings-form .row .text-field .field-number {
    margin-bottom: 0; }
  .json-form-wrapper .json-form .settings-form .field-string,
  .json-form-wrapper .json-form .settings-form .field-number,
  .json-form-wrapper .json-form .settings-form .text-field {
    margin-bottom: 15px; }
    .json-form-wrapper .json-form .settings-form .field-string:last-of-type,
    .json-form-wrapper .json-form .settings-form .field-number:last-of-type,
    .json-form-wrapper .json-form .settings-form .text-field:last-of-type {
      margin-bottom: 0; }
  .json-form-wrapper .json-form.disabled button {
    display: none; }
  .json-form-wrapper .json-form.disabled .text-field textarea,
  .json-form-wrapper .json-form.disabled .text-field input,
  .json-form-wrapper .json-form.disabled .text-field select {
    cursor: default; }
  .json-form-wrapper .json-form.disabled .text-field input:hover, .json-form-wrapper .json-form.disabled .text-field input:focus,
  .json-form-wrapper .json-form.disabled .text-field textarea:hover,
  .json-form-wrapper .json-form.disabled .text-field textarea:focus {
    border: 1px solid #c4c4c4;
    outline: none;
    box-shadow: none; }
  .json-form-wrapper .json-form textarea,
  .json-form-wrapper .json-form input[type="text"],
  .json-form-wrapper .json-form input[type="number"],
  .json-form-wrapper .json-form input[type="password"] {
    width: 100%;
    box-sizing: border-box; }
  .json-form-wrapper .json-form .vertical-layout-item {
    margin-bottom: 15px; }
  .json-form-wrapper .code-error {
    margin: 4px 0 0 17px;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: #ed382a; }
