.modal-wrapper .modal.configuration-show-modal {
  padding: 20px 5px 20px 20px;
  width: 640px; }
  .modal-wrapper .modal.configuration-show-modal header h3 {
    z-index: 2;
    position: relative;
    margin-bottom: 0; }
  .modal-wrapper .modal.configuration-show-modal .close-button {
    margin-right: 12px;
    z-index: 2; }
  .modal-wrapper .modal.configuration-show-modal .content {
    padding-top: 15px;
    position: relative; }
    .modal-wrapper .modal.configuration-show-modal .content:before {
      display: block;
      content: "";
      width: calc(100% - 15px);
      height: 30px;
      position: absolute;
      z-index: 1;
      top: -12px;
      left: 0;
      background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 100%); }
    .modal-wrapper .modal.configuration-show-modal .content .scrollable {
      max-height: 80vh;
      overflow: auto;
      padding-right: 15px; }

.configuration-show-modal .header {
  background-color: #f7f8f9;
  border: 1px solid #c4c4c4;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  padding: 20px; }

.configuration-show-modal .label-like {
  color: #999999;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin: 0 0 12px 0; }

.configuration-show-modal .name-wrapper {
  margin-right: 50px; }
  .configuration-show-modal .name-wrapper .name {
    display: flex;
    width: 310px;
    min-height: 44px;
    background: rgba(153, 153, 153, 0.2);
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 15px;
    font-family: "Neue Hans Kendrick", sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #222222; }

.configuration-show-modal .component {
  display: flex;
  margin-top: 10px; }
  .configuration-show-modal .component .icon {
    width: 44px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    flex-shrink: 0; }
    .configuration-show-modal .component .icon img {
      width: 100%;
      height: 100%; }
  .configuration-show-modal .component .desc {
    color: #999999;
    margin-left: 12px; }
    .configuration-show-modal .component .desc h4 {
      font-size: 16px;
      font-weight: bold;
      margin: 5px 0 0;
      line-height: 19px; }
    .configuration-show-modal .component .desc h5 {
      margin: 0;
      font-weight: normal;
      line-height: 16px; }
  .configuration-show-modal .component.deprecated .desc {
    color: #ed382a; }
  .configuration-show-modal .component.deprecated .tooltip-bubble {
    padding: 10px;
    font-size: 16px;
    font-weight: normal;
    font-family: "Inter UI", sans-serif;
    font-size: 13px; }
  .configuration-show-modal .component.deprecated .icon {
    border-color: #ed382a;
    background: #facdcb;
    position: relative; }
    .configuration-show-modal .component.deprecated .icon:after {
      content: "";
      width: 18px;
      height: 18px;
      display: block;
      background: url("../../../../images/exclamation-triangle.png") center/contain no-repeat;
      position: absolute;
      top: 13px;
      left: 14px; }

.configuration-show-modal .description-header {
  margin-top: 20px; }
