.select-single-value,
.select-option {
  display: -webkit-flex;
  -webkit-flex-flow: row nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }
  .select-single-value .gravatar-image,
  .select-option .gravatar-image {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px; }
    .select-single-value .gravatar-image .inicials,
    .select-option .gravatar-image .inicials {
      font-size: 7px; }
