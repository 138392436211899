@import "../../../scssShared/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;

  :global(.search-button) {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .tableHeader,
  .tableRow {
    display: contents;
  }

  .tableRow {
    font-size: 14px;
  }

  .tableHeader {
    font-size: 11px;
    font-weight: 700;
    color: $color_grey;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .tableRow:not(:last-child) {
    .tableCell {
      border-bottom: solid 1px #e6e6e6;
    }
  }

  .tableCell,
  .tableHeader > div {
    padding: 10px 8px;
  }

  .user {
    display: flex;
    align-items: center;
    gap: 15px;

    &.inactive .name,
    &.inactive .role {
      color: $border_color_grey_light;
    }

    .name {
      font-size: 16px;
      font-weight: 700;
      color: $color_black;
      display: flex;
      align-items: center;
      gap: 5px;

      .badge {
        font-weight: 400;
      }
    }

    .role {
      color: $color_grey_lighter;
      font-size: 13px;
    }
  }

  .adminAccess {
    font-size: 12px;
    font-style: italic;
    margin-top: 5px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .linkedEntity {
    display: flex;
    align-items: center;
    gap: 6px;

    &.inactive {
      .icon,
      .link,
      .name,
      .badge {
        color: $border_color_grey_light;
        border-color: #ddd;
      }
    }

    .icon {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border: $border_grey_light_thin;
      border-radius: 50%;
    }

    .link {
      text-transform: none;
      font-size: 14px;
      text-decoration-color: transparent;
      transition: all 0.1s ease;

      &:hover {
        text-decoration: underline;
        color: $color_primary;
        text-decoration-color: $color_primary;
      }
    }
  }

  .badge {
    border: $border_grey_light_thin;
    border-radius: $br_small;
    font-size: 10px;
    height: 18px;
    text-transform: uppercase;
    padding: 0 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  :global(.avatar-wrapper) {
    width: 48px;
    height: 48px;

    :global(.inicials) {
      font-size: 13px;
    }
  }
}
