.data-flow.modifying {
  cursor: wait; }

.interaction-preventer {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0; }

.data-flow-wrapper {
  position: relative; }
  .data-flow-wrapper.modifying {
    pointer-events: none; }
  .data-flow-wrapper .cytoscape {
    width: 100%;
    height: 548px;
    display: block; }
  .data-flow-wrapper .center-button,
  .data-flow-wrapper .arrange-button {
    font-size: 40px;
    padding: 5px;
    z-index: 1;
    position: absolute;
    bottom: 10px;
    color: #c4c4c4; }
    .data-flow-wrapper .center-button:hover,
    .data-flow-wrapper .arrange-button:hover {
      background-color: #ffffff;
      opacity: 1;
      color: #999999; }
  .data-flow-wrapper .center-button {
    right: 10px; }
  .data-flow-wrapper .arrange-button {
    right: 65px; }
  .data-flow-wrapper .quick-tip {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(34, 34, 34, 0.8);
    color: #ffffff;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    width: 360px;
    height: 42px;
    box-sizing: border-box;
    transition: all 0.25s;
    overflow: hidden; }
    .data-flow-wrapper .quick-tip.page-1 {
      height: 42px; }
    .data-flow-wrapper .quick-tip.page-2 {
      height: 126px; }
    .data-flow-wrapper .quick-tip.page-3 {
      height: 62px; }
    .data-flow-wrapper .quick-tip.closed {
      width: 32px;
      height: 32px;
      text-align: center; }
      .data-flow-wrapper .quick-tip.closed .show-quick-tip-icon {
        cursor: pointer;
        margin: 0 auto;
        padding: 8px; }
    .data-flow-wrapper .quick-tip .quick-tip-close-button {
      margin-left: 4px; }
    .data-flow-wrapper .quick-tip .text {
      margin-left: 4px;
      margin-bottom: 0; }
    .data-flow-wrapper .quick-tip .pagination {
      width: 55px;
      flex-shrink: 0;
      text-align: right;
      padding-right: 10px; }
      .data-flow-wrapper .quick-tip .pagination .control {
        padding: 5px;
        cursor: pointer; }
        .data-flow-wrapper .quick-tip .pagination .control.disabled {
          cursor: not-allowed;
          opacity: 0.5; }
      .data-flow-wrapper .quick-tip .pagination .page {
        display: inline-block;
        text-align: center;
        width: 15px; }
