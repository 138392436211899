.tag-form {
  display: flex;
  justify-content: space-between; }
  .tag-form .custom-text-field .text-field label,
  .tag-form .label-like {
    display: block;
    color: #999999;
    font-family: "Inter UI", sans-serif;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    font-weight: bold; }
  .tag-form .custom-text-field .text-field label {
    margin-bottom: 8px; }
  .tag-form .colors-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-left: 25px; }
    .tag-form .colors-buttons .colors-box {
      margin-right: 20px; }
    .tag-form .colors-buttons .colors-wrapper {
      position: relative; }
    .tag-form .colors-buttons .buttons {
      display: flex;
      margin-bottom: 8px; }
      .tag-form .colors-buttons .buttons .icon-button {
        margin-left: 5px; }
