.app {
  height: 100%;
  min-width: 1200px; }
  .app .invalid-browser-message {
    background: #fe7f66; }
    .app .invalid-browser-message .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #222222;
      height: 50px; }
      .app .invalid-browser-message .wrapper .left-part {
        display: flex;
        align-items: center; }
        .app .invalid-browser-message .wrapper .left-part .exclamation-icon {
          font-size: 20px;
          display: block;
          margin-right: 15px; }
        .app .invalid-browser-message .wrapper .left-part p {
          font-size: 14px;
          display: block; }
          .app .invalid-browser-message .wrapper .left-part p a {
            text-transform: none;
            color: #222222;
            font-weight: bold;
            font-size: 14px; }
      .app .invalid-browser-message .wrapper .right-part {
        display: flex;
        align-items: center; }
        .app .invalid-browser-message .wrapper .right-part .warning-close {
          line-height: normal;
          text-transform: uppercase;
          cursor: pointer;
          letter-spacing: 1.5px;
          font-size: 11px;
          border: 1px solid #222222;
          border-radius: 6px;
          padding: 10px 9px 9px;
          transition: all 0.15s ease-out;
          text-decoration: none;
          color: #222222;
          flex-shrink: 0;
          font-weight: 500; }
          .app .invalid-browser-message .wrapper .right-part .warning-close:hover {
            color: #222222;
            background: rgba(255, 255, 255, 0.15); }
          .app .invalid-browser-message .wrapper .right-part .warning-close:active, .app .invalid-browser-message .wrapper .right-part .warning-close:focus {
            color: #222222;
            background: rgba(255, 255, 255, 0.25); }
  .app .custom-toastify {
    box-sizing: border-box;
    width: auto; }
    .app .custom-toastify.Toastify__toast-container--top-right {
      top: 80px; }
    .app .custom-toastify .toast {
      opacity: 0.85;
      margin-left: auto;
      margin-right: auto;
      width: 380px;
      border-radius: 8px;
      padding: 12px 20px;
      min-height: 60px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      font-family: "Inter UI", sans-serif;
      font-size: 12px;
      line-height: 125%;
      font-weight: 400;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor: default; }
      .app .custom-toastify .toast .toast-body {
        flex-basis: 310px;
        box-sizing: border-box;
        word-wrap: break-word;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center; }
      .app .custom-toastify .toast .toast-text {
        max-width: 310px; }
        .app .custom-toastify .toast .toast-text a {
          text-transform: none;
          color: #ffffff;
          font-size: 14px; }
          .app .custom-toastify .toast .toast-text a:hover {
            color: #ffffff;
            text-decoration: none; }
      .app .custom-toastify .toast .link {
        font-family: "Inter UI", sans-serif;
        display: table;
        text-transform: uppercase !important;
        cursor: pointer;
        letter-spacing: 0.15em;
        font-size: 10px !important;
        line-height: 10px;
        border-radius: 4px;
        transition: all 0.15s ease-out;
        text-decoration: none;
        flex-shrink: 0;
        white-space: pre;
        font-weight: 500;
        padding: 4px 6px;
        margin-top: 4px; }
      .app .custom-toastify .toast .buttons {
        text-align: center; }
      .app .custom-toastify .toast .toast-icon {
        font-size: 24px;
        margin-right: 16px; }
      .app .custom-toastify .toast .toast-close {
        cursor: pointer;
        font-size: 14px;
        padding: 4px;
        margin-right: -4px; }
        .app .custom-toastify .toast .toast-close:hover {
          opacity: 0.6; }
      .app .custom-toastify .toast.toast-error {
        background: #fdf4f3;
        color: #ed382a;
        border: 1px solid #ed382a; }
        .app .custom-toastify .toast.toast-error .toast-close {
          color: #ed382a; }
        .app .custom-toastify .toast.toast-error .link {
          color: #ed382a;
          border: 1px solid #ed382a; }
          .app .custom-toastify .toast.toast-error .link:hover, .app .custom-toastify .toast.toast-error .link:active, .app .custom-toastify .toast.toast-error .link:focus {
            color: #ffffff;
            background: #ed382a; }
      .app .custom-toastify .toast.toast-success {
        background: #f4f9f5;
        color: #41b05e;
        border: 1px solid #41b05e; }
        .app .custom-toastify .toast.toast-success .toast-close {
          color: #41b05e; }
        .app .custom-toastify .toast.toast-success .link {
          color: #41b05e;
          border: 1px solid #41b05e; }
          .app .custom-toastify .toast.toast-success .link:hover, .app .custom-toastify .toast.toast-success .link:active, .app .custom-toastify .toast.toast-success .link:focus {
            color: #ffffff;
            background: #41b05e; }
      .app .custom-toastify .toast.toast-info {
        background: #ffffff;
        color: #222222;
        border: 1px solid #c4c4c4; }
        .app .custom-toastify .toast.toast-info .toast-close {
          color: #222222; }
        .app .custom-toastify .toast.toast-info .link {
          color: #fabe53;
          border: 1px solid #fabe53; }
          .app .custom-toastify .toast.toast-info .link:hover, .app .custom-toastify .toast.toast-info .link:active, .app .custom-toastify .toast.toast-info .link:focus {
            color: #ffffff;
            background: #fabe53; }
      .app .custom-toastify .toast.wide {
        width: 440px; }
        .app .custom-toastify .toast.wide .toast-body {
          flex-basis: 355px; }
        .app .custom-toastify .toast.wide .toast-text {
          max-width: 355px; }

.toastify-on-top .custom-toastify.Toastify__toast-container--top-right {
  top: 1em; }

.monitoring-visible .custom-toastify.Toastify__toast-container--top-right {
  top: 135px; }
