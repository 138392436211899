.flex-row {
  display: flex;
  justify-content: space-between; }

.workspace-status,
.workspace-dawg,
.not-compact-author-name {
  margin-left: 20px;
  width: auto; }

.not-compact-author-name {
  max-width: 180px; }

.compact .row .dawg-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box; }
  .compact .row .dawg-name .dawg-link {
    margin-top: 0; }
