.ws-conf-sticky-nav {
  box-sizing: border-box;
  background: #f7f8f9;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #c4c4c4;
  left: 0;
  top: -65px;
  position: fixed;
  display: block;
  z-index: 99;
  transition: top 0.5s ease;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05); }
  .ws-conf-sticky-nav .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
    .ws-conf-sticky-nav .wrapper .vertical-line {
      width: 1px;
      background: #c4c4c4;
      height: 34px;
      float: left;
      margin: 0 10px 0 20px; }
    .ws-conf-sticky-nav .wrapper .left-side {
      display: flex;
      align-items: center; }
      .ws-conf-sticky-nav .wrapper .left-side h2 {
        margin: 0;
        color: #222222;
        font-size: 20px; }
        .ws-conf-sticky-nav .wrapper .left-side h2.mid {
          font-size: 18px; }
        .ws-conf-sticky-nav .wrapper .left-side h2.small {
          font-size: 16px; }
        .ws-conf-sticky-nav .wrapper .left-side h2.x-small {
          font-size: 14px; }
      .ws-conf-sticky-nav .wrapper .left-side .back-button {
        font-size: 20px;
        transition: all 0.15s ease-out;
        padding: 5px;
        margin-left: -5px;
        background: transparent;
        border: none;
        color: #999999;
        margin-right: 12px; }
        .ws-conf-sticky-nav .wrapper .left-side .back-button:hover {
          opacity: 0.5; }
    .ws-conf-sticky-nav .wrapper .right-side .button,
    .ws-conf-sticky-nav .wrapper .right-side .toggle-switch {
      float: left;
      margin-left: 10px; }
    .ws-conf-sticky-nav .wrapper .right-side .fieldset {
      margin: 0; }
