@import "../../../../../../../scssShared/variables.scss";

.workspace-gantt-chart {
  max-width: 918px;
  flex-shrink: 0;

  .gantt-container {
    overflow: visible;

    .gantt {
      .bar-label {
        font-family: $font_family_inter;
        font-weight: bold;
        font-size: 13px;
      }

      .bar-finished {
        .bar {
          fill: $color_green;
        }
      }

      .bar-running {
        .bar {
          fill: $color_blue;
        }
      }

      .bar-waiting,
      .bar-warning {
        .bar {
          fill: $color_primary;
        }
      }

      .bar-canceled {
        .bar {
          fill: $color_grey_darker;
        }
      }

      .bar-error {
        .bar {
          fill: $color_warning_red;
        }
      }

      .date {
        .lower-text {
          font-weight: bold;
          font-family: $font_family_inter;
          letter-spacing: 0.5px;
          font-size: 10px;
        }
      }
    }
  }

  .popup-wrapper {
    .custom-popup {
      white-space: nowrap;
      padding: 4px;
      color: $color_white;
      line-height: 24px;

      .component-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        float: left;
      }

      p {
        padding-right: 30px;

        &.red {
          color: $color_warning_red;
        }
      }
    }
  }
}
