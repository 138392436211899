.configuration-create .configuration-create-header {
  justify-content: space-between; }

.configuration-create .configuration-search {
  width: 280px; }

.configuration-create .filter-picker {
  margin-top: 30px;
  text-align: center; }
  .configuration-create .filter-picker button {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 5px; }
    .configuration-create .filter-picker button.primary {
      border: 1px solid transparent; }

.configuration-create .components-container {
  margin-top: 15px; }
  .configuration-create .components-container:after {
    content: " ";
    clear: both;
    display: table; }
  .configuration-create .components-container .col {
    float: left;
    width: 32.6%;
    box-sizing: border-box; }
  .configuration-create .components-container .col-2 {
    margin: 0 1.1%; }
  .configuration-create .components-container .component-item {
    margin-bottom: 15px; }
    .configuration-create .components-container .component-item.in-progress {
      opacity: 0.6; }
      .configuration-create .components-container .component-item.in-progress .component-item-header img {
        filter: grayscale(100%); }
      .configuration-create .components-container .component-item.in-progress .component-item-header:hover {
        cursor: not-allowed;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }
      .configuration-create .components-container .component-item.in-progress .component-item-header .development {
        width: 80px;
        flex-shrink: 0;
        font-size: 13px;
        text-align: center;
        margin-left: 10px; }
    .configuration-create .components-container .component-item .component-item-header {
      background-color: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      transition: all 0.15s ease-out;
      padding: 0 20px;
      height: 90px;
      box-sizing: border-box; }
      .configuration-create .components-container .component-item .component-item-header:hover {
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer; }
      .configuration-create .components-container .component-item .component-item-header .info {
        flex-grow: 1; }
        .configuration-create .components-container .component-item .component-item-header .info h3 {
          font-size: 16px;
          font-family: "Neue Hans Kendrick", sans-serif;
          font-weight: bold;
          line-height: 18px;
          margin: 0 0 2px; }
        .configuration-create .components-container .component-item .component-item-header .info p {
          font-size: 11px;
          margin: 0; }
      .configuration-create .components-container .component-item .component-item-header img {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        padding: 27px 14px 27px 0px; }
    .configuration-create .components-container .component-item .create-configuration-form {
      background: #f7f8f9;
      width: calc(100% - 20px);
      margin-left: 10px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-left: 1px solid #c4c4c4;
      border-right: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      padding: 20px 20px 25px;
      box-sizing: border-box;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05), 0px 3px 6px rgba(0, 0, 0, 0.05) inset; }
      .configuration-create .components-container .component-item .create-configuration-form p {
        font-size: 13px;
        line-height: 17px; }
