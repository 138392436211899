.update-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .update-modal-wrapper .update-modal {
    width: 480px;
    background: linear-gradient(270deg, #fabe53 0%, #ffa200 100%);
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1001;
    padding: 20px; }
    .update-modal-wrapper .update-modal header {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .update-modal-wrapper .update-modal header img {
        width: 32px;
        height: auto;
        margin-right: 5px; }
      .update-modal-wrapper .update-modal header h3 {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin: 0; }
    .update-modal-wrapper .update-modal section .text {
      font-size: 14px;
      color: #ffffff;
      line-height: 135%; }
      .update-modal-wrapper .update-modal section .text p {
        margin: 0; }
    .update-modal-wrapper .update-modal section .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 10px; }
      .update-modal-wrapper .update-modal section .actions button {
        font-weight: bold;
        font-size: 11px; }
        .update-modal-wrapper .update-modal section .actions button .update-icon {
          font-size: 12px; }
      .update-modal-wrapper .update-modal section .actions .later {
        color: white;
        text-decoration: underline;
        background: transparent;
        border: 0;
        margin-left: -12px; }
        .update-modal-wrapper .update-modal section .actions .later:hover {
          text-decoration: none; }
      .update-modal-wrapper .update-modal section .actions .update {
        border: 0;
        background: #ffffff;
        color: #fabe53; }
  .update-modal-wrapper .update-modal-overlay {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8); }
