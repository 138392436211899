.not-authorized {
  display: flex;
  justify-content: center;
  align-items: center; }
  .not-authorized .left-side {
    flex-shrink: 0;
    width: 275px;
    margin: 110px 0; }
  .not-authorized .right-side {
    color: #222222;
    margin-left: 70px; }
    .not-authorized .right-side h1 {
      font-size: 32px;
      line-height: 38px;
      max-width: 400px; }
    .not-authorized .right-side h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0; }
