.variables-header {
  justify-content: space-between;
  margin-top: 20px; }
  .variables-header .right-part {
    display: flex;
    align-items: center; }
    .variables-header .right-part .variables-count {
      color: #999999;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-family: "Inter UI", sans-serif;
      margin: 0; }
    .variables-header .right-part .save-button {
      margin-left: 15px; }
  .variables-header .title-wrapper {
    display: flex; }
  .variables-header .copy-button {
    margin-left: 5px;
    width: 22px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
    cursor: pointer;
    transition: all 0.15s ease-out; }
    .variables-header .copy-button.disabled {
      cursor: not-allowed;
      opacity: 0.5; }
    .variables-header .copy-button:hover:not(.disabled) {
      background: #c4c4c4;
      color: #ffffff; }

.variables-content {
  padding: 20px; }
  .variables-content .manage-button {
    margin: 10px auto;
    display: block; }
  .variables-content .labels:after {
    content: "";
    display: table;
    clear: both; }
  .variables-content .labels .label {
    width: 45%;
    float: left;
    font-family: "Inter UI", sans-serif;
    color: #999999;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-top: 0; }
    .variables-content .labels .label:first-child {
      margin-right: 20px; }
  .variables-content .form-row {
    margin-bottom: 10px; }
    .variables-content .form-row:last-of-type {
      margin-bottom: 0; }
    .variables-content .form-row:after {
      content: "";
      display: table;
      clear: both; }
    .variables-content .form-row input {
      width: 45%;
      float: left;
      box-sizing: border-box; }
      .variables-content .form-row input:first-child {
        margin-right: 20px; }
    .variables-content .form-row button {
      float: right;
      margin-top: 5px; }
    .variables-content .form-row .error-message {
      width: 100%;
      float: left; }

.variables-summary .table {
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word; }
  .variables-summary .table td {
    width: 50%;
    vertical-align: top;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    color: #777777; }
  .variables-summary .table .var-copy-button {
    margin-left: 5px;
    font-size: 12px;
    display: inline-block;
    border-radius: 50%;
    color: #c4c4c4;
    cursor: pointer;
    transition: all 0.15s ease-out; }
    .variables-summary .table .var-copy-button:hover {
      opacity: 0.5; }

.variables-summary .variables-tooltip {
  max-width: 600px; }

.variables-code-editor .error-message {
  margin: 4px 0 0 17px;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: #ed382a;
  width: 100%; }
