.loading-circle {
  position: relative;
  cursor: wait;
  width: 28px;
  height: 28px; }
  .loading-circle:before {
    margin: -13px 0 0 -13px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    border-radius: 24px;
    background-clip: padding-box;
    border-color: rgba(250, 190, 83, 0.25);
    border-width: 2px;
    border-style: solid;
    border-top-color: #fabe53;
    animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite; }
  .loading-circle.white:before {
    border-color: rgba(196, 196, 196, 0.25);
    border-top-color: #c4c4c4; }
  .loading-circle span {
    color: transparent; }
