.modal-list .modal-body .modal-list-form {
  position: relative; }
  .modal-list .modal-body .modal-list-form .send-emails-checkbox {
    position: absolute;
    bottom: 62px;
    right: 0; }
  .modal-list .modal-body .modal-list-form .create-user-list .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #c4c4c4; }
    .modal-list .modal-body .modal-list-form .create-user-list .form-row:last-of-type {
      border: none;
      margin: 0; }
    .modal-list .modal-body .modal-list-form .create-user-list .form-row .password-field {
      width: calc(100% - 41px);
      margin-top: 10px; }
    .modal-list .modal-body .modal-list-form .create-user-list .form-row .user-field {
      width: 45%; }
      .modal-list .modal-body .modal-list-form .create-user-list .form-row .user-field .error-message {
        margin-bottom: 0; }
    .modal-list .modal-body .modal-list-form .create-user-list .form-row.permissions {
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      border-bottom: 0;
      padding-bottom: 0; }
    .modal-list .modal-body .modal-list-form .create-user-list .form-row .vr-line {
      width: 1px;
      background: #c4c4c4;
      box-sizing: border-box;
      margin: 15px 0; }
    .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user {
      text-align: center;
      margin: 0 15px;
      user-select: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 54px;
      cursor: pointer; }
      .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user .avatar-wrapper {
        margin: 0 auto;
        width: 48px;
        height: 48px; }
        .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user .avatar-wrapper .inicials {
          font-size: 13px;
          line-height: 16px;
          font-weight: bold; }
      .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user .permissions-name {
        margin: 3px 0 0;
        align-self: center;
        font-weight: bold;
        color: #c4c4c4;
        font-size: 11px;
        line-height: 13px; }
      .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user:first-child {
        margin-left: 0; }
      .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user:last-child {
        margin-right: 0; }
      .modal-list .modal-body .modal-list-form .create-user-list .form-row .permissions-user.active .permissions-name {
        color: #222222; }
  .modal-list .modal-body .modal-list-form .create-user-list .invite-more-btn {
    transition: none; }
    .modal-list .modal-body .modal-list-form .create-user-list .invite-more-btn.hidden {
      visibility: hidden; }
  .modal-list .modal-body .modal-list-form .pagination-lbl {
    font-family: Neue Hans Kendrick;
    letter-spacing: 1.95px;
    font-size: 13px; }
  .modal-list .modal-body .modal-list-form .workspaces {
    border: 1px solid #c4c4c4;
    padding: 0 10px;
    border-radius: 4px;
    max-height: 40vh;
    overflow: auto;
    background-color: #f7f9fb;
    position: relative; }
    .modal-list .modal-body .modal-list-form .workspaces .loading-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: wait;
      background: transparent; }
    .modal-list .modal-body .modal-list-form .workspaces .workspace,
    .modal-list .modal-body .modal-list-form .workspaces .select-all {
      border-bottom: 1px solid #c4c4c4;
      display: -webkit-flex;
      -webkit-flex-flow: row nowrap;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace:last-of-type,
      .modal-list .modal-body .modal-list-form .workspaces .select-all:last-of-type {
        border-bottom: none; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace .horizontal-line,
      .modal-list .modal-body .modal-list-form .workspaces .select-all .horizontal-line {
        border-left: 1px solid #c4c4c4;
        width: 1px;
        margin: 0 10px;
        align-self: stretch; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace .bold-text,
      .modal-list .modal-body .modal-list-form .workspaces .select-all .bold-text {
        font-weight: bold;
        font-size: 11px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #999999;
        padding-top: 1px; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace .checkbox-field,
      .modal-list .modal-body .modal-list-form .workspaces .select-all .checkbox-field {
        margin: 10px 5px;
        display: flex;
        align-items: center;
        align-self: stretch; }
        .modal-list .modal-body .modal-list-form .workspaces .workspace .checkbox-field label,
        .modal-list .modal-body .modal-list-form .workspaces .select-all .checkbox-field label {
          font-size: 13px;
          line-height: 16px;
          text-transform: none;
          padding-left: 14px;
          align-self: stretch;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-weight: 400; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace .checkmark,
      .modal-list .modal-body .modal-list-form .workspaces .select-all .checkmark {
        top: auto;
        left: 0;
        width: 14px;
        height: 14px; }
        .modal-list .modal-body .modal-list-form .workspaces .workspace .checkmark svg,
        .modal-list .modal-body .modal-list-form .workspaces .select-all .checkmark svg {
          margin-bottom: 5px;
          font-size: 8px; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace .fieldset,
      .modal-list .modal-body .modal-list-form .workspaces .select-all .fieldset {
        margin-bottom: 0; }
      .modal-list .modal-body .modal-list-form .workspaces .workspace .toggle-switch-wrapper,
      .modal-list .modal-body .modal-list-form .workspaces .select-all .toggle-switch-wrapper {
        display: -webkit-flex;
        -webkit-flex-flow: row nowrap;
        display: flex;
        flex-flow: row nowrap;
        margin: 8px 5px 8px 10px;
        justify-content: flex-end; }
        .modal-list .modal-body .modal-list-form .workspaces .workspace .toggle-switch-wrapper .form-label,
        .modal-list .modal-body .modal-list-form .workspaces .select-all .toggle-switch-wrapper .form-label {
          display: none; }
        .modal-list .modal-body .modal-list-form .workspaces .workspace .toggle-switch-wrapper .toggle-switch,
        .modal-list .modal-body .modal-list-form .workspaces .select-all .toggle-switch-wrapper .toggle-switch {
          margin-top: 0; }
    .modal-list .modal-body .modal-list-form .workspaces .select-all .checkbox-field {
      margin: 8px 5px; }

.hide {
  display: none !important; }
