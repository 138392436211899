.dawg-detail .two-column-layout {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .dawg-detail .two-column-layout .left-column {
    flex-grow: 1;
    max-width: calc(100% - 380px); }
  .dawg-detail .two-column-layout .right-column {
    width: 360px;
    margin-left: 20px;
    flex-shrink: 0; }

.dawg-detail .workspace-activity-card {
  margin-top: 20px; }
