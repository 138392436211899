.conf-jobs-gantt-chart {
  max-width: calc(100% - 250px); }
  .conf-jobs-gantt-chart .gantt .bar-label {
    font-family: "Inter UI", sans-serif;
    font-weight: bold;
    font-size: 13px; }
  .conf-jobs-gantt-chart .gantt .bar-finished .bar {
    fill: #41b05e; }
  .conf-jobs-gantt-chart .gantt .bar-running .bar {
    fill: #229ace; }
  .conf-jobs-gantt-chart .gantt .bar-waiting .bar,
  .conf-jobs-gantt-chart .gantt .bar-warning .bar {
    fill: #fabe53; }
  .conf-jobs-gantt-chart .gantt .bar-canceled .bar {
    fill: #494a4a; }
  .conf-jobs-gantt-chart .gantt .bar-error .bar {
    fill: #ed382a; }
  .conf-jobs-gantt-chart .gantt .date .lower-text {
    font-weight: bold;
    font-family: "Inter UI", sans-serif;
    letter-spacing: 0.5px;
    font-size: 10px; }
  .conf-jobs-gantt-chart .gantt-container .gantt {
    transform: translateY(0.5px); }
  .conf-jobs-gantt-chart .gantt-container .popup-wrapper .custom-popup {
    white-space: nowrap;
    padding: 4px;
    color: #ffffff;
    display: flex;
    align-items: center; }
    .conf-jobs-gantt-chart .gantt-container .popup-wrapper .custom-popup .component-icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      float: left; }
    .conf-jobs-gantt-chart .gantt-container .popup-wrapper .custom-popup p {
      padding-right: 5px;
      font-size: 11px;
      line-height: 13px; }
      .conf-jobs-gantt-chart .gantt-container .popup-wrapper .custom-popup p.red {
        color: #ed382a; }
      .conf-jobs-gantt-chart .gantt-container .popup-wrapper .custom-popup p .duration {
        font-size: 9px; }

.configuration-jobs-chart-wrapper {
  display: flex;
  position: relative; }
  .configuration-jobs-chart-wrapper .background {
    position: absolute;
    transform: translateY(0.5px);
    pointer-events: none; }
    .configuration-jobs-chart-wrapper .background .b-grid .b-grid-row {
      stroke-width: 1px;
      stroke: #cbccd0; }
      .configuration-jobs-chart-wrapper .background .b-grid .b-grid-row:first-child {
        stroke: transparent; }
  .configuration-jobs-chart-wrapper .configurations .label {
    font-family: "Inter UI", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    color: #999999;
    margin: 0;
    line-height: 30px;
    padding-left: 8px; }
    .configuration-jobs-chart-wrapper .configurations .label span {
      color: #222222;
      float: right;
      font-size: 11px;
      font-family: "Inter UI", sans-serif; }
  .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper {
    display: flex;
    align-items: center;
    height: 46px;
    border-right: 1px solid rgba(196, 196, 196, 0.4);
    width: 234px;
    padding-right: 15px;
    min-width: 10%;
    word-wrap: break-word; }
    .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-name,
    .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-link {
      font-size: 16px;
      text-transform: none;
      text-decoration: none;
      font-weight: bold;
      padding-left: 8px;
      display: block;
      max-width: 100%;
      display: flex;
      align-items: center; }
      .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-name span,
      .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-link span {
        border-radius: 6px;
        color: #222222;
        margin: 0 0 0 -8px;
        padding: 4px 8px;
        display: block; }
      .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-name .component-icon,
      .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-link .component-icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        flex-shrink: 0; }
    .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-link:hover {
      background: rgba(250, 190, 83, 0.1);
      transition: all 150ms ease-out;
      border-radius: 6px; }
      .configuration-jobs-chart-wrapper .configurations .configuration-link-wrapper .configuration-link:hover span {
        color: #fabe53;
        transition: all 150ms ease-out; }
