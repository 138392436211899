.info-tooltip {
  font-size: 11px; }
  .info-tooltip .tooltip {
    padding: 10px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    text-transform: none;
    color: white;
    letter-spacing: normal;
    max-width: 350px; }
    .info-tooltip .tooltip a {
      font-size: 13px;
      text-decoration: underline;
      color: #ffffff;
      text-transform: none; }
      .info-tooltip .tooltip a:hover {
        color: #ffffff;
        text-decoration: none; }
