.entity-children-jobs-card-header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }

.entity-children-jobs-card-list {
  padding: 10px; }
  .entity-children-jobs-card-list .job-item {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    display: block; }
    .entity-children-jobs-card-list .job-item .content-border {
      box-sizing: border-box;
      border-bottom: 1px solid #cbccd0;
      padding: 10px 5px;
      margin: 0px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .entity-children-jobs-card-list .job-item .content-border .left-part {
        text-transform: none;
        display: -webkit-flex;
        -webkit-flex-flow: row nowrap;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;
        border-radius: 6px;
        margin: -4px 7px -4px -8px;
        padding: 4px 8px;
        width: 66%; }
        .entity-children-jobs-card-list .job-item .content-border .left-part .component-icon {
          width: 36px;
          height: 36px;
          flex-shrink: 0; }
        .entity-children-jobs-card-list .job-item .content-border .left-part .text-wrapper {
          width: calc(100% - 36px); }
        .entity-children-jobs-card-list .job-item .content-border .left-part h4 {
          font-family: "Inter UI", sans-serif;
          font-size: 12px;
          margin: 0 0 0 10px;
          line-height: 15px;
          word-break: break-word;
          word-wrap: break-word;
          width: calc(100% - 10px);
          color: #222222;
          transition: all 0.15s ease-out; }
        .entity-children-jobs-card-list .job-item .content-border .left-part:hover {
          background: rgba(250, 190, 83, 0.1);
          transition: all 150ms ease-out; }
          .entity-children-jobs-card-list .job-item .content-border .left-part:hover h4 {
            color: #fabe53; }
        .entity-children-jobs-card-list .job-item .content-border .left-part .date-info {
          margin: 0 0 0 10px;
          font-size: 10px;
          display: block;
          white-space: pre;
          word-wrap: normal; }
      .entity-children-jobs-card-list .job-item .content-border .right-part {
        text-transform: none;
        border-radius: 6px;
        margin: -4px -8px;
        padding: 4px 8px;
        text-decoration: none;
        display: flex;
        align-items: center; }
        .entity-children-jobs-card-list .job-item .content-border .right-part:hover {
          background: rgba(250, 190, 83, 0.1);
          transition: all 150ms ease-out; }
    .entity-children-jobs-card-list .job-item:last-child .content-border {
      border-bottom: 0; }
