.dawg-workspace-add .dawg-workspace-add-header {
  justify-content: space-between; }

.dawg-workspace-add .workspaces-loading {
  margin: 20px auto 0; }

.dawg-workspace-add .no-workspaces-found {
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  text-align: center;
  margin: 10px auto 0;
  width: 100%; }

.dawg-workspace-add .workspaces-section {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .dawg-workspace-add .workspaces-section .left-col,
  .dawg-workspace-add .workspaces-section .right-col {
    width: calc(50% - 5px);
    flex-shrink: 0; }

.dawg-workspace-add .workspace-item {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  margin-top: 10px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%; }
  .dawg-workspace-add .workspace-item.disabled {
    opacity: 0.6; }
  .dawg-workspace-add .workspace-item .upper-part {
    background: #ffffff;
    padding: 0 10px;
    border-top: 3px solid #999999;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .dawg-workspace-add .workspace-item .upper-part.finished {
      border-top: 3px solid #41b05e; }
    .dawg-workspace-add .workspace-item .upper-part.warning, .dawg-workspace-add .workspace-item .upper-part.waiting {
      border-top: 3px solid #fabe53; }
    .dawg-workspace-add .workspace-item .upper-part.error {
      border-top: 3px solid #ed382a; }
    .dawg-workspace-add .workspace-item .upper-part.canceled {
      border-top: 3px solid #222222; }
    .dawg-workspace-add .workspace-item .upper-part.running {
      border-top: 3px solid #229ace; }
  .dawg-workspace-add .workspace-item .status {
    width: 105px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .dawg-workspace-add .workspace-item .label {
    text-transform: uppercase;
    color: #999999;
    font-size: 11px;
    letter-spacing: 0.1em;
    font-weight: bold; }
  .dawg-workspace-add .workspace-item .workspace-item-header {
    border-bottom: 1px solid #cbccd0;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .dawg-workspace-add .workspace-item .workspace-name {
    margin: 8px 10px;
    color: #222222;
    font-size: 18px;
    line-height: 24px;
    font-family: "Neue Hans Kendrick", sans-serif;
    display: block; }
  .dawg-workspace-add .workspace-item .workspace-item-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    min-height: 46px; }
    .dawg-workspace-add .workspace-item .workspace-item-body .dawg .label {
      margin-right: 5px; }
    .dawg-workspace-add .workspace-item .workspace-item-body .dawg h4 {
      font-family: "Inter UI", sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 135%;
      color: #777777;
      margin: 0;
      display: inline; }
    .dawg-workspace-add .workspace-item .workspace-item-body .last-run {
      white-space: pre;
      flex-shrink: 0; }
      .dawg-workspace-add .workspace-item .workspace-item-body .last-run .last-run-ago {
        color: #777777;
        font-size: 14px;
        margin-left: 5px; }
  .dawg-workspace-add .workspace-item .tags {
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: #f7f8f9;
    border-top: 1px solid #c4c4c4;
    padding: 2.5px 20px;
    box-sizing: border-box; }
    .dawg-workspace-add .workspace-item .tags .label {
      margin-right: 5px; }
    .dawg-workspace-add .workspace-item .tags .tags-content {
      color: #777777;
      font-size: 14px; }
      .dawg-workspace-add .workspace-item .tags .tags-content .tag {
        margin: 2.5px; }
