.dawg-list:after {
  display: table;
  content: " ";
  clear: both; }

.dawg-list .left-column {
  width: 68.34%;
  float: left; }

.dawg-list .right-column {
  width: 30%;
  float: right; }

.dawg-list .no-dawgs-found {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  text-align: center; }
