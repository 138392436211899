.variables-injection-header {
  margin-top: 20px;
  justify-content: space-between; }
  .variables-injection-header .buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center; }
    .variables-injection-header .buttons .cancel-button {
      margin-right: 15px; }

.variables-injection-body {
  padding: 15px; }
  .variables-injection-body p {
    padding: 5px 5px 0; }
    .variables-injection-body p.label {
      font-size: 11px;
      letter-spacing: 1px;
      color: #999999;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold; }
  .variables-injection-body .add-more {
    margin-left: 5px; }
  .variables-injection-body .variables .row {
    padding: 15px 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c4c4c4; }
    .variables-injection-body .variables .row:first-child {
      padding-top: 5px; }
    .variables-injection-body .variables .row:last-child {
      border-bottom: 0;
      padding-bottom: 0; }
    .variables-injection-body .variables .row .variable-select-field {
      width: 70%; }
      .variables-injection-body .variables .row .variable-select-field > label {
        display: none; }
      .variables-injection-body .variables .row .variable-select-field.error .select-field__control {
        border-color: #ed382a; }
