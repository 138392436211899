.job-list-page.entity-children-jobs .job-list-content .table .thead .table-head.status {
  padding-left: 33px; }

.job-list-page.entity-children-jobs .job-list-content .table-cell .children-name {
  display: flex;
  align-items: center; }
  .job-list-page.entity-children-jobs .job-list-content .table-cell .children-name img {
    width: 36px;
    height: 36px;
    margin-right: 5px; }
  .job-list-page.entity-children-jobs .job-list-content .table-cell .children-name .conf-ws-name-wrapper {
    width: 250px;
    word-break: break-word;
    word-wrap: break-word; }
    .job-list-page.entity-children-jobs .job-list-content .table-cell .children-name .conf-ws-name-wrapper .conf-name {
      display: block;
      font-weight: bold; }
    .job-list-page.entity-children-jobs .job-list-content .table-cell .children-name .conf-ws-name-wrapper .ws-name {
      display: block;
      font-weight: normal;
      color: #999999;
      font-size: 10px;
      line-height: 12px;
      margin-top: 0px; }

.job-list-page.entity-children-jobs .load-more-button {
  margin: 40px auto 0;
  display: table; }
