/*
 * ---------------------
 * Shake animation start
 * ---------------------
 */
@keyframes animation-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0); }
  40%,
  60% {
    transform: translate3d(3px, 0, 0); } }

/*
 * -------------------
 * Shake animation end
 * -------------------
 */
/*
 * ----------------------
 * Rotate animation start
 * ----------------------
 */
@keyframes animation-rotate {
  100% {
    transform: rotate(360deg); } }

/*
 * --------------------
 * Rotate animation end
 * --------------------
 */
/*
  * -----------
  * Loading row
  * -----------
  */
.loading-row {
  position: relative;
  cursor: wait;
  width: 100%;
  min-height: 24px; }
  .loading-row:before {
    margin: -13px 0 0 -13px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    border-radius: 24px;
    background-clip: padding-box;
    border-color: rgba(250, 190, 83, 0.25);
    border-width: 2px;
    border-style: solid;
    border-top-color: #fabe53;
    animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite; }
  .loading-row span {
    color: transparent; }

/*
 * FADE effect
 */
.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  transition: opacity 0.2s ease-in;
  opacity: 1; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  transition: opacity 0.2s ease-out;
  opacity: 0.01; }

/*
 * NEW VERSION BAR MARGIN-TOP effect
 */
.versionbaranimation-enter {
  margin-top: -50px; }

.versionbaranimation-enter-active {
  transition: all 0.2s ease-in;
  margin-top: 0px; }

.versionbaranimation-exit {
  margin-top: 0px; }

.versionbaranimation-exit-active {
  transition: all 0.2s ease-in;
  margin-top: -50px; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

button {
  cursor: pointer; }

.button {
  font-family: "Inter UI", sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 500;
  padding: 6px 12px 5px;
  transition: all 0.15s ease-out;
  line-height: normal; }
  .button .icon {
    margin-right: 3px; }
  .button:focus {
    outline: none; }
  .button.full-width {
    width: 100%; }
  .button.big {
    height: 44px;
    font-size: 13px;
    letter-spacing: 0.15em; }
    .button.big.loading {
      position: relative;
      cursor: wait; }
      .button.big.loading:before {
        width: 18px;
        height: 18px;
        margin: -11px 0 0 -11px; }
  .button.small {
    height: 34px;
    font-size: 11px;
    letter-spacing: 0.05em; }
    .button.small.loading {
      position: relative;
      cursor: wait; }
      .button.small.loading:before {
        width: 18px;
        height: 18px;
        margin: -11px 0 0 -11px; }
  .button.tag {
    padding: 0 8px;
    height: 24px; }
  .button.primary {
    background: linear-gradient(to right, #fac364 0%, #fabe53 50%, #ffb433 100%);
    background-size: 200%;
    background-position: 0% 0%;
    color: #ffffff;
    border: 0; }
    .button.primary:hover {
      background-position: 50% 0%;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.primary:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      box-shadow: none; }
    .button.primary:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(250, 190, 83, 0.4); }
    .button.primary.inner-shadow {
      box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15); }
      .button.primary.inner-shadow.border {
        border: 1px solid #dca84b; }
  .button.none {
    background: transparent;
    border: none;
    color: #999999; }
  .button.link, .button.link-primary {
    background: transparent;
    color: #777777;
    text-decoration: underline;
    border: none;
    padding: 2px; }
    .button.link:hover, .button.link-primary:hover {
      text-decoration: none; }
    .button.link:focus, .button.link-primary:focus {
      outline: none;
      box-shadow: none; }
  .button.link-primary {
    color: #fabe53; }
  .button.link-black {
    background: transparent;
    color: #222222;
    text-decoration: underline;
    border: none;
    padding: 2px;
    font-weight: 400; }
    .button.link-black:hover {
      text-decoration: none; }
    .button.link-black:focus {
      outline: none;
      box-shadow: none; }
  .button.white, .button.white-red {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    color: #777777; }
    .button.white:hover, .button.white-red:hover {
      color: #222222;
      border-color: #999999;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.white:focus, .button.white-red:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(153, 153, 153, 0.4); }
    .button.white:disabled, .button.white-red:disabled {
      color: #e9eaea;
      cursor: not-allowed; }
      .button.white:disabled:hover, .button.white-red:disabled:hover {
        border: 1px solid #c4c4c4;
        color: #e9eaea;
        box-shadow: none; }
    .button.white.turned-off, .button.white-red.turned-off {
      color: #e9eaea; }
      .button.white.turned-off:hover, .button.white-red.turned-off:hover {
        color: #222222; }
      .button.white.turned-off:disabled, .button.white-red.turned-off:disabled {
        color: #e9eaea; }
    .button.white.turned-on, .button.white-red.turned-on {
      color: #ffffff; }
      .button.white.turned-on:hover, .button.white-red.turned-on:hover {
        opacity: 0.7;
        box-shadow: none; }
      .button.white.turned-on:focus, .button.white-red.turned-on:focus {
        box-shadow: none; }
      .button.white.turned-on:disabled, .button.white-red.turned-on:disabled {
        color: #ffffff;
        opacity: 1; }
    .button.white.turned-on-green, .button.white-red.turned-on-green {
      background: #41b05e;
      border-color: #41b05e; }
      .button.white.turned-on-green:disabled, .button.white-red.turned-on-green:disabled {
        border-color: #41b05e; }
    .button.white.turned-on-red, .button.white-red.turned-on-red {
      background: #ed382a;
      border-color: #ed382a; }
      .button.white.turned-on-red:disabled, .button.white-red.turned-on-red:disabled {
        border-color: #ed382a; }
    .button.white.turned-on-yellow, .button.white-red.turned-on-yellow {
      background: #fbb962;
      border-color: #fbb962; }
      .button.white.turned-on-yellow:disabled, .button.white-red.turned-on-yellow:disabled {
        border-color: #fbb962; }
    .button.white.turned-on-purple, .button.white-red.turned-on-purple {
      background: #8d47b9;
      border-color: #8d47b9; }
      .button.white.turned-on-purple:disabled, .button.white-red.turned-on-purple:disabled {
        border-color: #8d47b9; }
    .button.white.turned-on-blue, .button.white-red.turned-on-blue {
      background: #229ace;
      border-color: #229ace; }
      .button.white.turned-on-blue:disabled, .button.white-red.turned-on-blue:disabled {
        border-color: #229ace; }
    .button.white.turned-on-light-blue, .button.white-red.turned-on-light-blue {
      background: #44a6d7;
      border-color: #44a6d7; }
      .button.white.turned-on-light-blue:disabled, .button.white-red.turned-on-light-blue:disabled {
        border-color: #44a6d7; }
  .button.white-red {
    color: #ed382a;
    border-color: rgba(237, 56, 42, 0.5); }
    .button.white-red:hover {
      color: #ed382a;
      border-color: #ed382a; }
    .button.white-red:disabled {
      color: rgba(237, 56, 42, 0.3); }
      .button.white-red:disabled:hover {
        color: rgba(237, 56, 42, 0.3);
        border-color: rgba(237, 56, 42, 0.5); }
  .button.red {
    background: #ed382a;
    color: #ffffff;
    border: 0; }
    .button.red:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.red:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(153, 153, 153, 0.4); }
    .button.red:disabled {
      opacity: 0.5; }
      .button.red:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.green {
    background: #41b05e;
    color: #ffffff;
    border: 0; }
    .button.green:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.green:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(153, 153, 153, 0.4); }
    .button.green:disabled {
      opacity: 0.5; }
      .button.green:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.blue {
    background: #229ace;
    color: #ffffff;
    border: 0; }
    .button.blue:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.blue:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(153, 153, 153, 0.4); }
    .button.blue:disabled {
      opacity: 0.5; }
      .button.blue:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.yellow {
    background: #fbb962;
    color: #ffffff;
    border: 0; }
    .button.yellow:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.yellow:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(153, 153, 153, 0.4); }
    .button.yellow:disabled {
      opacity: 0.5; }
      .button.yellow:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.green {
    background: #41b05e;
    color: #ffffff;
    border: 0; }
    .button.green:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.green:focus {
      outline: none;
      box-shadow: 0 0 4px 1.5px rgba(153, 153, 153, 0.4); }
  .button.grey {
    background: #c4c4c4;
    color: #ffffff;
    border: 0; }
    .button.grey:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .button.grey:disabled {
      opacity: 0.5; }
      .button.grey:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.transparent-grey {
    border: none;
    color: #999999;
    background: transparent;
    font-family: "Neue Hans Kendrick", sans-serif;
    font-size: 13px; }
    .button.transparent-grey:hover {
      text-decoration: underline; }
    .button.transparent-grey:disabled {
      opacity: 0.5; }
      .button.transparent-grey:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.transparent-red {
    border: none;
    color: #ed382a;
    background: transparent;
    padding: 0; }
    .button.transparent-red:hover {
      opacity: 0.5; }
    .button.transparent-red:disabled {
      opacity: 0.5; }
      .button.transparent-red:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.transparent-primary {
    border: none;
    color: #fabe53;
    background: transparent;
    padding: 0; }
    .button.transparent-primary:hover {
      opacity: 0.5; }
    .button.transparent-primary:disabled {
      opacity: 0.5; }
      .button.transparent-primary:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
  .button.loading {
    position: relative;
    cursor: wait; }
    .button.loading:before {
      margin: -13px 0 0 -13px;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 50%;
      top: 50%;
      content: "";
      border-radius: 24px;
      background-clip: padding-box;
      border-color: rgba(255, 255, 255, 0.25);
      border-width: 2px;
      border-style: solid;
      border-top-color: #ffffff;
      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite; }
    .button.loading.white:before {
      border-color: rgba(196, 196, 196, 0.25);
      border-top-color: #c4c4c4; }
    .button.loading span {
      color: transparent; }
  .button.cursor-wait {
    cursor: wait; }
