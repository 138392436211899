.dawg-ws-header {
  justify-content: space-between; }
  .dawg-ws-header .left-part {
    display: flex;
    align-items: center; }
    .dawg-ws-header .left-part .titles {
      margin-right: 18px; }
    .dawg-ws-header .left-part .dawg-ws-search {
      width: 230px; }
  .dawg-ws-header .right-part {
    display: flex;
    align-items: center; }
  .dawg-ws-header .view-picker {
    margin-right: -8px;
    padding-left: 12px;
    border-left: 1px solid #c4c4c4;
    margin-left: 20px; }
    .dawg-ws-header .view-picker button.grey svg path {
      transition: all 0.15s ease-out; }
    .dawg-ws-header .view-picker button.grey:hover, .dawg-ws-header .view-picker button.grey.active {
      opacity: 1; }
      .dawg-ws-header .view-picker button.grey:hover svg path, .dawg-ws-header .view-picker button.grey.active svg path {
        fill: #222222; }
