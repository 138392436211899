.icon-button {
  font-size: 16px;
  padding: 8px;
  min-height: 34px;
  min-width: 34px;
  border: 0;
  background: transparent;
  transition: all 0.15s ease-out;
  cursor: pointer; }
  .icon-button:focus {
    box-shadow: none;
    outline: none; }
  .icon-button:hover {
    opacity: 0.5; }
  .icon-button:active {
    opacity: 1; }
  .icon-button.primary {
    color: #fabe53; }
  .icon-button.default {
    color: #222222; }
  .icon-button.red {
    color: #ed382a; }
  .icon-button.grey {
    color: #999999; }
  .icon-button.grey-darker {
    color: #777777; }
  .icon-button.black {
    color: #222222; }
  .icon-button.yellow {
    color: #fbb962; }
  .icon-button.white {
    color: #ffffff; }
  .icon-button.green {
    color: #41b05e; }
  .icon-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #999999; }
  .icon-button.small {
    padding: 0px;
    min-height: 18px;
    min-width: 18px; }
  .icon-button.faded {
    opacity: 0.5; }
    .icon-button.faded:hover {
      opacity: 1; }
    .icon-button.faded:disabled {
      opacity: 0.5; }
