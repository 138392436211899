.header-container {
  height: 128px;
  margin-bottom: 55px; }
  .header-container .logo-container {
    width: 350px; }
  .header-container .logo {
    float: left; }
    .header-container .logo > img {
      margin: 8px 0;
      height: 60px; }
  .header-container .client-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    position: absolute;
    padding: 8px;
    box-sizing: border-box;
    top: 20px;
    left: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    .header-container .client-logo > img {
      width: 100%;
      height: auto; }
      .header-container .client-logo > img.dummy {
        width: 80%;
        margin-left: 2px; }
  .header-container .product-name {
    float: right;
    font-family: "Inter UI", sans-serif;
    color: #ffffff;
    font-size: 11px;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal; }
    .header-container .product-name.one-line {
      margin: 33px 4px 0px 60px; }
    .header-container .product-name.two-line {
      margin: 26px 4px 0px 60px;
      line-height: 14px; }
    .header-container .product-name span {
      display: block; }
    .header-container .product-name .strong {
      font-weight: bold; }
  .header-container .user-menu img {
    width: 48px;
    height: 48px;
    border-radius: 50%; }
  .header-container .primary {
    background: linear-gradient(270deg, #fabe53 0%, #ffa200 100%); }
    .header-container .primary .wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: 80px; }
  .header-container .secondary {
    height: 47px;
    background-color: #f7f8f9;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    position: relative; }
    .header-container .secondary .system-info {
      border: 1px solid #c4c4c4;
      border-radius: 6px;
      background: #ffffff;
      position: absolute;
      top: 4px;
      right: 20px;
      height: 50px;
      color: #777777;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      z-index: 2;
      opacity: 0.9;
      font-size: 8px;
      line-height: 8px;
      letter-spacing: 0.01em; }
      .header-container .secondary .system-info .disc-info {
        display: flex;
        align-items: center;
        height: 28px;
        padding-right: 15px;
        border-right: 1px solid #c4c4c4;
        margin-right: 15px;
        margin-left: 17px; }
        .header-container .secondary .system-info .disc-info .label {
          font-weight: bold;
          font-size: 8px;
          line-height: 10px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          width: 34px; }
        .header-container .secondary .system-info .disc-info .pie {
          width: 22px;
          height: 22px;
          margin-right: 5px; }
          .header-container .secondary .system-info .disc-info .pie circle {
            fill: #777777; }
          .header-container .secondary .system-info .disc-info .pie path {
            fill: #fabe53; }
        .header-container .secondary .system-info .disc-info .percentage-usage {
          font-weight: bold;
          font-size: 14px;
          display: block;
          line-height: 14px;
          margin-bottom: 1px; }
      .header-container .secondary .system-info .workers-info {
        display: flex;
        align-items: center;
        height: 28px; }
        .header-container .secondary .system-info .workers-info .workers-icon {
          font-size: 16px;
          display: block; }
        .header-container .secondary .system-info .workers-info .details {
          margin-left: 6px; }
        .header-container .secondary .system-info .workers-info .workers-count {
          font-weight: bold;
          font-size: 14px;
          display: block;
          line-height: 14px;
          margin-bottom: 1px; }
      .header-container .secondary .system-info .cores-info {
        display: flex;
        align-items: center;
        height: 28px;
        padding-right: 15px;
        border-right: 1px solid #c4c4c4;
        margin-right: 15px; }
        .header-container .secondary .system-info .cores-info .cores-icon {
          font-size: 14px;
          position: relative;
          display: block;
          width: 17px;
          height: 22px;
          margin-top: 6px; }
          .header-container .secondary .system-info .cores-info .cores-icon .info-tooltip {
            height: 9px;
            width: 9px;
            font-size: 9px;
            display: block;
            position: absolute;
            border: 2px solid #eeeeee;
            background: #eeeeee;
            bottom: 0px;
            right: -2px;
            border-radius: 50%;
            display: flex; }
        .header-container .secondary .system-info .cores-info .cores-tooltip {
          text-transform: none;
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          max-width: 275px;
          padding: 10px 8px;
          pointer-events: auto !important; }
          .header-container .secondary .system-info .cores-info .cores-tooltip:hover {
            visibility: visible !important;
            opacity: 1 !important; }
          .header-container .secondary .system-info .cores-info .cores-tooltip a {
            text-transform: none;
            color: #ffffff;
            transition: none;
            font-size: 13px; }
        .header-container .secondary .system-info .cores-info .details {
          margin-left: 5px; }
        .header-container .secondary .system-info .cores-info .system-load {
          font-weight: bold;
          font-size: 14px;
          display: block;
          line-height: 14px;
          margin-bottom: 1px; }
      .header-container .secondary .system-info .times-icon {
        margin-left: 7px;
        margin-right: 7px;
        font-size: 14px; }
  .header-container .primary-nav a,
  .header-container .secondary-nav a {
    text-transform: uppercase;
    text-decoration: none;
    font-family: "Neue Hans Kendrick", sans-serif; }
  .header-container .primary-nav a {
    font-size: 15px;
    line-height: 36px;
    letter-spacing: 2px;
    margin: 10px;
    padding-left: 44px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    color: #fddeac; }
    .header-container .primary-nav a .inline-icon {
      font-size: 16px;
      width: 36px;
      height: 36px;
      margin-right: 8px;
      box-sizing: border-box;
      display: block;
      border: 2px solid #fddeac;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0; }
    .header-container .primary-nav a .icon-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center; }
      .header-container .primary-nav a .icon-wrapper .code-branch {
        margin-left: 4px; }
    .header-container .primary-nav a.is-active, .header-container .primary-nav a:hover {
      color: #ffffff; }
      .header-container .primary-nav a.is-active .inline-icon, .header-container .primary-nav a:hover .inline-icon {
        border: 2px solid #ffffff; }
    .header-container .primary-nav a.is-active {
      position: relative; }
      .header-container .primary-nav a.is-active:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-color: #f7f8f9;
        position: absolute;
        transform: rotate(45deg);
        left: 44px;
        right: 0;
        margin: 17px auto 0;
        z-index: 1; }
  .header-container .secondary-nav {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    padding-top: 15px;
    font-weight: 500; }
    .header-container .secondary-nav .chevron-icon {
      color: #999999; }
    .header-container .secondary-nav .middot {
      display: inline-block;
      position: relative;
      width: 5px;
      height: 12px; }
      .header-container .secondary-nav .middot span {
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0; }
    .header-container .secondary-nav a,
    .header-container .secondary-nav .link-like {
      font-size: 12px;
      margin: 0px 12px;
      letter-spacing: 2px;
      color: #999999;
      display: inline-block;
      cursor: pointer;
      text-transform: uppercase; }
      .header-container .secondary-nav a.is-active,
      .header-container .secondary-nav .link-like.is-active {
        color: #fabe53; }
    .header-container .secondary-nav .has-ws-icon,
    .header-container .secondary-nav .has-conf-icon,
    .header-container .secondary-nav .has-dawg-icon {
      position: relative;
      padding-left: 25px; }
      .header-container .secondary-nav .has-ws-icon .prefix-icon,
      .header-container .secondary-nav .has-conf-icon .prefix-icon,
      .header-container .secondary-nav .has-dawg-icon .prefix-icon {
        position: absolute;
        font-size: 20px;
        left: 0;
        top: -3px; }
    .header-container .secondary-nav .has-dawg-icon {
      padding-left: 30px; }
    .header-container .secondary-nav .has-conf-icon {
      padding-left: 32px; }
  .header-container .user-menu-wrapper {
    width: 320px;
    display: -webkit-flex;
    -webkit-flex-flow: row nowrap;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center; }
    .header-container .user-menu-wrapper .icon-menu {
      display: -webkit-flex;
      -webkit-flex-flow: row nowrap;
      display: flex;
      flex-flow: row nowrap; }
      .header-container .user-menu-wrapper .icon-menu .monitoring-badge {
        display: block;
        cursor: pointer; }
        .header-container .user-menu-wrapper .icon-menu .monitoring-badge.loading {
          cursor: wait; }
      .header-container .user-menu-wrapper .icon-menu a .icon,
      .header-container .user-menu-wrapper .icon-menu .monitoring-badge .icon {
        display: flex;
        color: #ffffff;
        opacity: 0.6;
        font-size: 16px;
        padding: 5px 20px 0 0; }
      .header-container .user-menu-wrapper .icon-menu a.is-active .icon, .header-container .user-menu-wrapper .icon-menu a:hover .icon,
      .header-container .user-menu-wrapper .icon-menu .monitoring-badge.is-active .icon,
      .header-container .user-menu-wrapper .icon-menu .monitoring-badge:hover .icon {
        opacity: 1; }
      .header-container .user-menu-wrapper .icon-menu a.is-active {
        position: relative; }
        .header-container .user-menu-wrapper .icon-menu a.is-active:after {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          background-color: #f7f8f9;
          position: absolute;
          transform: rotate(45deg);
          top: 28px;
          margin: 17px auto 0;
          z-index: 1; }
    .header-container .user-menu-wrapper .gravatar-image {
      background-color: #ffffff;
      border-radius: 50%;
      border: 2px solid #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); }
    .header-container .user-menu-wrapper .user-menu-toggle-button {
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      font-size: 16px;
      color: #ffffff; }
      .header-container .user-menu-wrapper .user-menu-toggle-button .gravatar-image {
        float: left;
        margin-right: 5px; }
    .header-container .user-menu-wrapper .user-menu .icon {
      width: 20px;
      text-align: center;
      margin-right: 5px; }
    .header-container .user-menu-wrapper .user-menu .instance-info {
      background: #f7f8f9;
      border-top: 1px solid #c4c4c4;
      margin: 10px -15px -15px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px; }
      .header-container .user-menu-wrapper .user-menu .instance-info span {
        color: #999999;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        cursor: default; }
        .header-container .user-menu-wrapper .user-menu .instance-info span:hover {
          color: #999999; }
        .header-container .user-menu-wrapper .user-menu .instance-info span.location-value, .header-container .user-menu-wrapper .user-menu .instance-info span.version-value, .header-container .user-menu-wrapper .user-menu .instance-info span.build-date-value {
          font-weight: bold; }
      .header-container .user-menu-wrapper .user-menu .instance-info .location-info,
      .header-container .user-menu-wrapper .user-menu .instance-info .version-info,
      .header-container .user-menu-wrapper .user-menu .instance-info .build-date-info {
        border: 1px solid #c4c4c4;
        background: #ffffff;
        width: 100%;
        text-align: center;
        border-radius: 4px;
        padding: 6px;
        box-sizing: border-box; }
        .header-container .user-menu-wrapper .user-menu .instance-info .location-info span,
        .header-container .user-menu-wrapper .user-menu .instance-info .version-info span,
        .header-container .user-menu-wrapper .user-menu .instance-info .build-date-info span {
          display: block; }
      .header-container .user-menu-wrapper .user-menu .instance-info .location-info span {
        display: block; }
      .header-container .user-menu-wrapper .user-menu .instance-info .version-info,
      .header-container .user-menu-wrapper .user-menu .instance-info .build-date-info {
        display: flex;
        justify-content: center;
        align-items: center; }
        .header-container .user-menu-wrapper .user-menu .instance-info .version-info .version-value,
        .header-container .user-menu-wrapper .user-menu .instance-info .version-info .build-date-value,
        .header-container .user-menu-wrapper .user-menu .instance-info .build-date-info .version-value,
        .header-container .user-menu-wrapper .user-menu .instance-info .build-date-info .build-date-value {
          margin-left: 4px; }
