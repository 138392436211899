.password-set .invited-person {
  margin: 0;
  text-align: center; }

.password-set .form-wrapper {
  display: -webkit-flex;
  -webkit-flex-flow: column nowrap;
  display: flex;
  flex-flow: column nowrap; }
  .password-set .form-wrapper .form-body {
    display: -webkit-flex;
    -webkit-flex-flow: column nowrap;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
    .password-set .form-wrapper .form-body .title {
      margin: 32px 0 0 0; }
    .password-set .form-wrapper .form-body #forgetPassword {
      background-color: transparent;
      margin-bottom: 0;
      padding-bottom: 10px;
      border-radius: 0;
      box-shadow: none; }
    .password-set .form-wrapper .form-body .repeat-password {
      margin-bottom: 10px; }
    .password-set .form-wrapper .form-body button {
      margin-bottom: 32px; }
