.oauth-note-modal button {
  margin: 0 auto;
  display: table; }

.oauth-note-modal .name-wrapper label {
  margin-left: 16px; }

.oauth-note-modal .name-wrapper .name-tooltip {
  position: absolute;
  top: 6px; }
