.avatar-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .avatar-wrapper .avatar-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; }
  .avatar-wrapper .inicials {
    color: #fabe53;
    position: relative;
    font-size: 11px;
    font-weight: 500; }
  .avatar-wrapper .disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffffff;
    opacity: 0.5; }
  .avatar-wrapper.disabled-look img {
    filter: grayscale(100%); }
  .avatar-wrapper.disabled-look .inicials {
    color: #999999; }
