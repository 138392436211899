.toggle-switch {
  text-align: center;
  display: inline-block;
  transition: all 0.15s ease-out; }
  .toggle-switch .fieldset {
    position: relative;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 20px;
    height: 34px;
    background: #fabe53;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
    border: 0;
    transition: all 0.15s ease-out;
    display: flex;
    align-items: center; }
  .toggle-switch input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 34px;
    margin: 0;
    cursor: pointer;
    pointer-events: auto;
    left: 0; }
    .toggle-switch input[type="radio"]:checked + label {
      color: #222222;
      opacity: 1; }
    .toggle-switch input[type="radio"]:checked + label + .switch,
    .toggle-switch input[type="radio"]:checked + label:nth-of-type(n) + .switch {
      left: 50%; }
    .toggle-switch input[type="radio"]:not(:checked) {
      z-index: 2; }
  .toggle-switch label {
    width: 50%;
    position: relative;
    z-index: 1;
    float: left;
    cursor: pointer;
    font-size: 11px;
    line-height: 13px;
    padding: 0;
    text-transform: uppercase;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.75;
    text-align: center;
    margin: 0;
    letter-spacing: 0.05em; }
  .toggle-switch .switch {
    width: 48%;
    position: absolute;
    top: 3px;
    left: 3px;
    height: 28px;
    border-radius: 20px;
    transition: all 0.5s;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); }
  .toggle-switch.disabled .fieldset {
    background: #e9eaea;
    opacity: 0.5; }
  .toggle-switch.disabled label {
    cursor: not-allowed; }
  .toggle-switch.disabled input[type="radio"] {
    cursor: not-allowed; }
  .toggle-switch.multiline {
    margin-top: 12px; }
    .toggle-switch.multiline .fieldset {
      height: 40px; }
    .toggle-switch.multiline .switch {
      height: 34px; }
    .toggle-switch.multiline label {
      padding: 0 10px;
      box-sizing: border-box; }
  .toggle-switch.green .switch {
    background: #41b05e; }
  .toggle-switch.red .switch {
    background: #ed382a; }
  .toggle-switch.green .fieldset, .toggle-switch.red .fieldset {
    background: #ffffff;
    border: 1px solid #c4c4c4; }
  .toggle-switch.green label, .toggle-switch.red label {
    color: #222222; }
  .toggle-switch.green .switch, .toggle-switch.red .switch {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    top: 2px; }
  .toggle-switch.green input[type="radio"]:checked + label, .toggle-switch.red input[type="radio"]:checked + label {
    color: #ffffff; }

.toggle-switch-wrapper label {
  display: block; }

.toggle-switch-wrapper .toggle-switch .fieldset {
  margin-top: 0; }
  .toggle-switch-wrapper .toggle-switch .fieldset.small {
    height: 28px; }
  .toggle-switch-wrapper .toggle-switch .fieldset .switch.small {
    height: 22px; }
  .toggle-switch-wrapper .toggle-switch .fieldset label.small {
    padding: 7px 0;
    margin-bottom: 0; }
