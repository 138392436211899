.above-paper-left {
  position: absolute;
  top: -44px;
  left: 0; }

.above-paper-right {
  position: absolute;
  top: -44px;
  right: 0; }
  .above-paper-right.action-buttons button,
  .above-paper-right.action-buttons .version-history {
    float: left;
    margin-left: 10px; }
    .above-paper-right.action-buttons button.bigger-spacing,
    .above-paper-right.action-buttons .version-history.bigger-spacing {
      margin-left: 15px; }
  .above-paper-right.action-buttons .version-history {
    color: #222222;
    font-size: 11px;
    letter-spacing: 1px;
    display: flex;
    height: 34px;
    align-items: center;
    font-family: "Inter UI", sans-serif; }
  .above-paper-right p {
    float: left;
    margin: 11px 0 0;
    color: #999999;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Inter UI", sans-serif; }

.paper-header {
  box-sizing: border-box;
  min-height: 95px;
  width: 100%;
  border: 1px solid #c4c4c4;
  background: #f7f8f9;
  margin-bottom: -1px;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05); }
  .paper-header.small {
    min-height: 75px;
    padding: 10px 20px; }
    .paper-header.small .vertical-line {
      height: 46px;
      width: 1px;
      background-color: #c4c4c4;
      display: inline-block;
      margin: 0 20px; }
  .paper-header .sorting-form .select-field label,
  .paper-header .segment-name-form label,
  .paper-header .label {
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    font-family: "Neue Hans Kendrick", sans-serif;
    letter-spacing: 1px;
    color: #999999;
    display: block;
    text-transform: uppercase; }
  .paper-header .label {
    margin: 0 0 8px; }
  .paper-header h3 {
    font-size: 13px;
    color: #999999;
    text-transform: uppercase;
    margin: 0;
    font-family: "Neue Hans Kendrick", sans-serif;
    letter-spacing: 1px; }
    .paper-header h3 span {
      color: #c4c4c4; }
  .paper-header .subtitle {
    font-family: "Inter UI", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    color: #999999;
    margin: 0; }
  .paper-header .back-link {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
    font-family: "Neue Hans Kendrick", sans-serif;
    letter-spacing: 1px;
    text-decoration: none;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 0;
    border-right: 1px solid #c4c4c4;
    padding-right: 18px;
    margin-right: 20px;
    padding: 6px 18px 6px 12px;
    margin-left: -6px; }
    .paper-header .back-link:hover span {
      opacity: 0.5; }
  .paper-header .navigation-block {
    display: flex;
    align-items: center; }
