.dawg-ws-filter-form .filter {
  display: flex;
  align-items: center;
  float: left;
  min-width: auto; }
  .dawg-ws-filter-form .filter label {
    margin: 0 3px 0 0;
    color: #999999;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 11px;
    margin-top: 1px; }
  .dawg-ws-filter-form .filter > div {
    flex: 1; }
  .dawg-ws-filter-form .filter .select-field__control {
    height: 24px;
    border: 0; }
    .dawg-ws-filter-form .filter .select-field__control.select-field__control--is-focused {
      box-shadow: none; }
  .dawg-ws-filter-form .filter .select-field__value-container {
    opacity: 1;
    padding: 0; }
  .dawg-ws-filter-form .filter .select-field__single-value {
    position: static;
    top: auto;
    transform: translateY(0%);
    margin: 0;
    max-width: 100%; }
    .dawg-ws-filter-form .filter .select-field__single-value .select-single-value {
      font-size: 11px;
      color: #222222;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: "Inter UI", sans-serif; }
  .dawg-ws-filter-form .filter .select-field__indicators .select-field__indicator-separator {
    display: none; }
  .dawg-ws-filter-form .filter .select-field__indicators .select-field__indicator {
    font-size: 11px;
    color: #222222;
    padding: 0;
    margin-top: -1px;
    margin-left: 2px; }
  .dawg-ws-filter-form .filter .select-field__menu {
    width: 82px;
    right: -10px; }
    .dawg-ws-filter-form .filter .select-field__menu .select-field__option {
      font-size: 11px;
      color: #222222;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: "Inter UI", sans-serif; }
      .dawg-ws-filter-form .filter .select-field__menu .select-field__option.select-field__option--is-selected {
        color: #ffffff; }
