.logout-page {
  background-color: #ffffff;
  width: 100%;
  height: 100%; }
  .logout-page .left-part {
    width: 57%;
    float: left;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    box-sizing: border-box; }
    .logout-page .left-part h1 {
      margin: 10px 0 0;
      font-size: 32px;
      line-height: 38px; }
      .logout-page .left-part h1 strong {
        display: block;
        color: #fabe53; }
    .logout-page .left-part p {
      margin: 10px 0 0;
      font-size: 18px;
      line-height: 22px;
      color: #777777; }
      .logout-page .left-part p a {
        color: #fabe53;
        font-size: 18px;
        text-transform: none;
        font-weight: bold; }
    .logout-page .left-part .login-button {
      margin: 25px 0 0;
      width: 150px; }
  .logout-page .right-part {
    width: 43%;
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 620px; }
    .logout-page .right-part .team-avatars-wrapper {
      height: 100%;
      max-height: 720px;
      width: 100%;
      position: relative;
      background-image: url("../../images/meiro-uni-symbol.svg");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 64% auto; }
      .logout-page .right-part .team-avatars-wrapper .member {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        position: absolute;
        overflow: hidden;
        transition: opacity 0.5s ease-in-out; }
        .logout-page .right-part .team-avatars-wrapper .member.visible {
          opacity: 1; }
        .logout-page .right-part .team-avatars-wrapper .member.hidden {
          opacity: 0; }
        .logout-page .right-part .team-avatars-wrapper .member img {
          width: 100%;
          height: 100%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-1 {
          top: 3.33%;
          right: 54.19%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-2 {
          top: 9.3%;
          right: 72.9%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-3 {
          top: 20.55%;
          right: 66.29%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-4 {
          top: 32.64%;
          right: 80.64%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-5 {
          top: 45.13%;
          right: 71.45%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-6 {
          top: 55.69%;
          right: 85%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-7 {
          top: 63.88%;
          right: 66.29%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-8 {
          top: 78.47%;
          right: 74.67%; }
        .logout-page .right-part .team-avatars-wrapper .member.member-9 {
          top: 87.36%;
          right: 45.48%; }
  .logout-page:after {
    clear: both;
    content: "";
    display: block; }
