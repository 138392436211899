.workspace-gantt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  margin: 3px 0 0; }
  .workspace-gantt:last-child {
    border-bottom: 0; }
  .workspace-gantt .workspace-gantt-workspace-name {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin: 1.5px 0 0 5px;
    word-wrap: break-word;
    min-width: 5%;
    text-decoration: none;
    text-transform: none;
    line-height: 21px; }
    .workspace-gantt .workspace-gantt-workspace-name p {
      padding: 3px 6px;
      border-radius: 6px;
      color: #222222;
      display: block; }
    .workspace-gantt .workspace-gantt-workspace-name:hover p {
      color: #fabe53;
      background: rgba(250, 190, 83, 0.1);
      transition: all 150ms ease-out; }
  .workspace-gantt .configurations-list {
    margin-top: 4px; }
    .workspace-gantt .configurations-list:after {
      display: block;
      content: "";
      clear: both; }
  .workspace-gantt .configuration-item {
    display: table;
    height: 39px;
    color: #999999;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    margin: 4px 0 0 5px;
    padding: 4px 6px;
    border-radius: 6px;
    text-transform: none;
    box-sizing: border-box; }
    .workspace-gantt .configuration-item:first-child {
      margin-top: 0px; }
    .workspace-gantt .configuration-item:after {
      content: "";
      display: block;
      clear: both; }
    .workspace-gantt .configuration-item .component-icon {
      width: 25px;
      height: 25px;
      margin-top: 3px;
      margin-right: 5px;
      float: left; }
    .workspace-gantt .configuration-item span {
      margin-top: 10px;
      float: left; }
      .workspace-gantt .configuration-item span.red {
        color: #ed382a; }
    .workspace-gantt .configuration-item.link:hover {
      cursor: pointer;
      color: #fabe53;
      background: rgba(250, 190, 83, 0.1);
      transition: all 150ms ease-out; }
