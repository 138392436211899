.oauth-list-header {
  justify-content: space-between;
  position: relative; }
  .oauth-list-header .oauth-search {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 280px; }
