.whole-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin-top: -57px;
  margin-bottom: -75px;
  height: calc(100vh - 125px);
  width: 100%;
  position: fixed;
  z-index: 100; }
  .whole-page-loading .back-to-login {
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.15em;
    display: block; }
  .whole-page-loading.full-height {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh; }
  .whole-page-loading .loading-content {
    text-align: center; }
    .whole-page-loading .loading-content img {
      width: 120px;
      animation: spin 4s linear infinite; }
    .whole-page-loading .loading-content h2 {
      margin: 20px 0 0;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      max-width: 340px;
      color: #222222; }
    .whole-page-loading .loading-content.error img {
      animation-play-state: paused; }
    .whole-page-loading .loading-content.error button {
      margin-top: 10px;
      padding-left: 15px;
      padding-right: 15px; }
