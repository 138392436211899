.above-paper-left button.back-button {
  font-size: 20px;
  transition: all 0.15s ease-out;
  margin-left: 15px;
  padding: 5px;
  background: transparent;
  border: none;
  color: #999999; }
  .above-paper-left button.back-button:hover {
    opacity: 0.5; }

.above-paper-right .ws-switch-margin {
  margin-right: 20px; }

.ws-conf-sticky-nav .continue-on-failure,
.above-paper-right .continue-on-failure {
  margin-top: -3px; }

.ws-conf-sticky-nav .ws-switch-margin,
.above-paper-right .ws-switch-margin {
  margin-top: -3px; }
  .ws-conf-sticky-nav .ws-switch-margin .fieldset,
  .above-paper-right .ws-switch-margin .fieldset {
    height: 40px; }
  .ws-conf-sticky-nav .ws-switch-margin input[type="radio"],
  .above-paper-right .ws-switch-margin input[type="radio"] {
    height: 40px; }
  .ws-conf-sticky-nav .ws-switch-margin .switch,
  .above-paper-right .ws-switch-margin .switch {
    height: 34px; }

.ws-conf-sticky-nav .delimiter,
.above-paper-right .delimiter {
  width: 1px;
  height: 32px;
  background: #c4c4c4;
  float: left;
  margin-right: 10px;
  margin-left: 20px; }

.entity-detail-header-wrapper .toggle-switch {
  float: left;
  margin-left: 10px; }
  .entity-detail-header-wrapper .toggle-switch .fieldset {
    margin-top: 0;
    float: none; }

.entity-detail-header-wrapper .tags .label {
  font-size: 11px;
  font-family: "Inter UI", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #999999; }

.entity-detail-header-wrapper .tags .tags-content {
  display: -webkit-flex;
  -webkit-flex-flow: row nowrap;
  display: flex;
  flex-flow: row nowrap;
  flex-wrap: wrap; }
  .entity-detail-header-wrapper .tags .tags-content > .tag {
    margin: 0 5px 5px 0px; }
    .entity-detail-header-wrapper .tags .tags-content > .tag.wait-cursor {
      cursor: wait; }
  .entity-detail-header-wrapper .tags .tags-content .tag-picker {
    margin-right: 0; }

.entity-detail-header-wrapper .entity-detail-header {
  align-items: flex-start;
  justify-content: space-between; }
  .entity-detail-header-wrapper .entity-detail-header .tags {
    margin: 0 50px -5px; }
  .entity-detail-header-wrapper .entity-detail-header .name-tags-wrapper {
    display: flex;
    max-width: 66%; }
  .entity-detail-header-wrapper .entity-detail-header .users-run-wrapper {
    display: flex; }
  .entity-detail-header-wrapper .entity-detail-header .entity-name-form-wrapper {
    flex-shrink: 0;
    flex-basis: 320px; }
  .entity-detail-header-wrapper .entity-detail-header .users {
    margin-right: 40px;
    min-width: 250px; }
    .entity-detail-header-wrapper .entity-detail-header .users .label {
      font-size: 11px;
      font-family: "Inter UI", sans-serif; }
    .entity-detail-header-wrapper .entity-detail-header .users .users-content {
      display: flex; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-content button {
        margin-left: 5px;
        margin-top: 2px; }
    .entity-detail-header-wrapper .entity-detail-header .users .users-avatars {
      display: flex;
      max-width: 185px;
      flex-wrap: wrap; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar {
        width: 32px;
        height: 32px;
        margin-left: -8px;
        position: relative;
        cursor: pointer;
        margin-bottom: 5px; }
        .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar .avatar-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid #f7f8f9;
          background: #ffffff; }
        .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar .inicials {
          font-size: 9px; }
        .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar:first-of-type {
          margin-left: 0px; }
        .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar:hover {
          z-index: 51; }
        .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar.disabled {
          cursor: default; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars.all-shown .user-avatar:nth-child(7n + 1) {
        margin-left: 0px; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-0 {
        z-index: 50; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-1 {
        z-index: 49; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-2 {
        z-index: 48; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-3 {
        z-index: 47; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-4 {
        z-index: 46; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-5 {
        z-index: 45; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-6 {
        z-index: 44; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-7 {
        z-index: 43; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-8 {
        z-index: 42; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-9 {
        z-index: 41; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-10 {
        z-index: 40; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-11 {
        z-index: 39; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-12 {
        z-index: 38; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-13 {
        z-index: 37; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-14 {
        z-index: 36; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-15 {
        z-index: 35; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-16 {
        z-index: 34; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-17 {
        z-index: 33; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-18 {
        z-index: 32; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-19 {
        z-index: 31; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-20 {
        z-index: 30; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-21 {
        z-index: 29; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-22 {
        z-index: 28; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-23 {
        z-index: 27; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-24 {
        z-index: 26; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-25 {
        z-index: 25; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-26 {
        z-index: 24; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-27 {
        z-index: 23; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-28 {
        z-index: 22; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-29 {
        z-index: 21; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-30 {
        z-index: 20; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-31 {
        z-index: 19; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-32 {
        z-index: 18; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-33 {
        z-index: 17; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-34 {
        z-index: 16; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-35 {
        z-index: 15; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-36 {
        z-index: 14; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-37 {
        z-index: 13; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-38 {
        z-index: 12; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-39 {
        z-index: 11; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-40 {
        z-index: 10; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-41 {
        z-index: 9; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-42 {
        z-index: 8; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-43 {
        z-index: 7; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-44 {
        z-index: 6; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-45 {
        z-index: 5; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-46 {
        z-index: 4; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-47 {
        z-index: 3; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-48 {
        z-index: 2; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-49 {
        z-index: 1; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .user-avatar-50 {
        z-index: 0; }
      .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .all-users-button {
        z-index: 50; }
        .entity-detail-header-wrapper .entity-detail-header .users .users-avatars .all-users-button .avatar-wrapper {
          background: #fabe53;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          color: #ffffff; }
    .entity-detail-header-wrapper .entity-detail-header .users .invite-user-button {
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: 50%; }
  .entity-detail-header-wrapper .entity-detail-header .run-section {
    text-align: right;
    flex-shrink: 0; }
    .entity-detail-header-wrapper .entity-detail-header .run-section .run-button {
      margin-bottom: 10px; }
    .entity-detail-header-wrapper .entity-detail-header .run-section.no-data-checkbox .run-button {
      margin-top: 15px; }

.entity-detail-header-wrapper .destination-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px; }
  .entity-detail-header-wrapper .destination-settings h3 {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #999999; }
  .entity-detail-header-wrapper .destination-settings button {
    margin-left: 10px; }

.entity-detail-header-wrapper .workspace-subheader {
  width: calc(100% - 40px);
  margin-left: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .entity-detail-header-wrapper .workspace-subheader .dawg-part {
    flex-shrink: 0;
    display: flex;
    max-width: 50%;
    align-items: center;
    font-size: 11px; }
    .entity-detail-header-wrapper .workspace-subheader .dawg-part .label {
      font-family: "Inter UI", sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      color: #999999;
      margin-right: 10px;
      margin-bottom: 0; }
      .entity-detail-header-wrapper .workspace-subheader .dawg-part .label .icon {
        margin-right: 4px; }
  .entity-detail-header-wrapper .workspace-subheader .tags {
    margin-bottom: -5px;
    max-width: 50%;
    display: flex;
    align-items: center; }
    .entity-detail-header-wrapper .workspace-subheader .tags .label {
      margin: 0 10px 5px 0;
      flex-shrink: 0; }
    .entity-detail-header-wrapper .workspace-subheader .tags .add-button {
      margin-bottom: 5px; }
  .entity-detail-header-wrapper .workspace-subheader .dawg-link {
    max-width: 300px; }

.entity-detail-header-wrapper .workspace-detail-header {
  min-height: auto;
  padding-top: 18px;
  padding-bottom: 18px;
  align-items: center; }
  .entity-detail-header-wrapper .workspace-detail-header .name-form {
    width: 405px; }
    .entity-detail-header-wrapper .workspace-detail-header .name-form label {
      width: 85px;
      flex-shrink: 0;
      margin-bottom: 0; }
    .entity-detail-header-wrapper .workspace-detail-header .name-form .text-field {
      display: flex;
      align-items: center; }
    .entity-detail-header-wrapper .workspace-detail-header .name-form .close-icon-button {
      left: auto;
      right: 310px; }
  .entity-detail-header-wrapper .workspace-detail-header .users-run-wrapper {
    min-height: 44px;
    align-items: center; }
    .entity-detail-header-wrapper .workspace-detail-header .users-run-wrapper .users {
      display: flex;
      align-items: center; }
      .entity-detail-header-wrapper .workspace-detail-header .users-run-wrapper .users .label {
        flex-shrink: 0;
        margin-bottom: 0; }
      .entity-detail-header-wrapper .workspace-detail-header .users-run-wrapper .users .users-content {
        flex-shrink: 0; }
        .entity-detail-header-wrapper .workspace-detail-header .users-run-wrapper .users .users-content .users-avatars {
          margin-left: 6px; }
  .entity-detail-header-wrapper .workspace-detail-header .run-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 220px;
    flex-shrink: 0; }
    .entity-detail-header-wrapper .workspace-detail-header .run-section .checkbox-field {
      margin-right: 10px; }
    .entity-detail-header-wrapper .workspace-detail-header .run-section .run-button {
      margin-bottom: 0; }
  .entity-detail-header-wrapper .workspace-detail-header .users {
    margin-right: 20px;
    min-width: 220px;
    max-width: 230px; }
    @media screen and (min-width: 1280px) {
      .entity-detail-header-wrapper .workspace-detail-header .users {
        margin-right: 60px; } }
    .entity-detail-header-wrapper .workspace-detail-header .users .users-content .users-avatars {
      max-width: 130px; }
      .entity-detail-header-wrapper .workspace-detail-header .users .users-content .users-avatars.all-shown .user-avatar:nth-child(7n + 1) {
        margin-left: -8px; }
      .entity-detail-header-wrapper .workspace-detail-header .users .users-content .users-avatars.all-shown .user-avatar:nth-child(5n + 1) {
        margin-left: 0; }
  .entity-detail-header-wrapper .workspace-detail-header .workers-tooltip {
    padding: 10px; }
  .entity-detail-header-wrapper .workspace-detail-header .workers-count {
    display: flex;
    align-items: center;
    width: 200px;
    flex-shrink: 0; }
    @media screen and (min-width: 1280px) {
      .entity-detail-header-wrapper .workspace-detail-header .workers-count {
        width: 210px; } }
    .entity-detail-header-wrapper .workspace-detail-header .workers-count .text-field {
      display: flex;
      align-items: center; }
    .entity-detail-header-wrapper .workspace-detail-header .workers-count .label {
      font-size: 11px;
      line-height: 13px;
      font-family: "Inter UI", sans-serif;
      margin: 0;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count .label .icon {
        margin-right: 5px; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count .label > span {
        width: 76px;
        display: block; }
    .entity-detail-header-wrapper .workspace-detail-header .workers-count button {
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      color: #999999;
      height: 18px;
      width: 16px;
      box-sizing: border-box;
      padding: 0;
      border: 0;
      background: transparent;
      transition: all 0.15s ease-out;
      margin: 2px; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count button:focus {
        outline: none;
        border: 0; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count button:hover:enabled {
        color: #222222; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count button.minus {
        margin-top: -2px; }
    .entity-detail-header-wrapper .workspace-detail-header .workers-count .input-wrapper {
      position: relative; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count .input-wrapper .max-workers-count {
        position: absolute;
        display: block;
        right: 13px;
        bottom: 9px;
        color: #a2a4a6;
        opacity: 0.6;
        font-size: 13px;
        line-height: 15px;
        pointer-events: none; }
    .entity-detail-header-wrapper .workspace-detail-header .workers-count .workers-count-input {
      -moz-appearance: textfield;
      box-sizing: border-box;
      width: 54px;
      height: 34px;
      padding: 0 30px 0 0;
      border-radius: 4px;
      text-align: right; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count .workers-count-input::-webkit-outer-spin-button, .entity-detail-header-wrapper .workspace-detail-header .workers-count .workers-count-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .entity-detail-header-wrapper .workspace-detail-header .workers-count.disabled {
      cursor: not-allowed; }
      .entity-detail-header-wrapper .workspace-detail-header .workers-count.disabled button,
      .entity-detail-header-wrapper .workspace-detail-header .workers-count.disabled input {
        cursor: not-allowed; }
