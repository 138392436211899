.data-out-card-header {
  margin-top: 20px;
  justify-content: space-between; }

.data-out-card-content .files {
  padding: 10px;
  position: relative; }
  .data-out-card-content .files a {
    text-decoration: none;
    text-transform: none; }
    .data-out-card-content .files a .out-file-item {
      cursor: pointer;
      font-size: 13px;
      background: linear-gradient(to right, #fac364 0%, #fabe53 50%, #ffb433 100%);
      background-size: 200%;
      background-position: 0% 0%;
      color: #ffffff;
      line-height: 28px;
      display: table;
      padding: 0 15px;
      border-radius: 14px; }
      .data-out-card-content .files a .out-file-item .copy-button {
        margin-left: 3px; }
      .data-out-card-content .files a .out-file-item:hover {
        background-position: 50% 0%;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1); }
  .data-out-card-content .files .row {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between; }
    .data-out-card-content .files .row:last-child {
      margin-bottom: 10px; }
    .data-out-card-content .files .row .download-button {
      padding: 4px;
      min-width: auto;
      min-height: auto; }
  .data-out-card-content .files .scrollable {
    padding-bottom: 0; }
    .data-out-card-content .files .scrollable.files-content {
      max-height: 145px;
      overflow: auto;
      padding-bottom: 18px;
      box-sizing: border-box;
      padding-right: 10px;
      margin-right: -10px; }
      .data-out-card-content .files .scrollable.files-content:after {
        content: "";
        width: 100%;
        height: 28px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 0; }

.data-out-card-content .download-all {
  padding-top: 12px;
  border-top: 1px solid #cbccd0;
  text-align: center;
  display: flex;
  justify-content: center; }
