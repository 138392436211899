.footer-container {
  padding: 40px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  display: flex;
  justify-content: center; }
  .footer-container .footer-content {
    display: flex; }
    .footer-container .footer-content .vertical-delimiter {
      width: 1px;
      height: 100%;
      background: #c4c4c4;
      margin: 0 20px; }
    .footer-container .footer-content > img {
      margin-top: 15px; }
      .footer-container .footer-content > img.client-logo {
        filter: brightness(0) saturate(100%) invert(78%) sepia(6%) saturate(8%) hue-rotate(22deg) brightness(100%) contrast(94%); }
    .footer-container .footer-content .meiro-footer-logo {
      height: 30px;
      width: auto; }
    .footer-container .footer-content .client-logo {
      display: none;
      height: 30px;
      width: auto; }
      .footer-container .footer-content .client-logo.loaded {
        display: block; }
