.code-editor-card-header {
  margin-top: 20px;
  justify-content: space-between; }
  .code-editor-card-header .buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center; }
  .code-editor-card-header .cancel-button {
    margin-right: 15px; }

.code-editor-card-content {
  padding: 20px; }
  .code-editor-card-content h4 {
    letter-spacing: 1px;
    font-weight: bold;
    color: #777777;
    text-transform: uppercase;
    font-size: 13px;
    position: relative;
    margin: 0 0 15px; }
  .code-editor-card-content .code-error {
    margin: 4px 0 0 17px;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: #ed382a; }
