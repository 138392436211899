.edit-entity-user-modal .form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 25px; }
  .edit-entity-user-modal .form-row .user {
    font-weight: bold;
    line-height: 52px; }
    .edit-entity-user-modal .form-row .user .user-avatar {
      width: 52px;
      height: 52px;
      float: left;
      display: block;
      margin-right: 8px; }
      .edit-entity-user-modal .form-row .user .user-avatar .inicials {
        font-size: 14px; }
  .edit-entity-user-modal .form-row .toggle-switch {
    margin: 0; }
    .edit-entity-user-modal .form-row .toggle-switch .fieldset {
      margin-bottom: 0; }
  .edit-entity-user-modal .form-row .toggle-switch-wrapper .form-label {
    display: none; }
  .edit-entity-user-modal .form-row .admin-permission-message {
    position: absolute;
    margin: 0;
    font-size: 11px;
    font-weight: bold;
    color: #ed382a;
    bottom: -18px;
    width: 100%;
    text-align: center; }
