.paper {
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  justify-content: space-between; }
  .paper.has-header {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05), 0px 3px 5px rgba(0, 0, 0, 0.05) inset; }
  .paper.loading {
    min-height: 50px;
    position: relative;
    cursor: wait; }
    .paper.loading:before {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      content: "";
      display: block;
      background: rgba(255, 255, 255, 0.75); }
    .paper.loading:after {
      position: absolute;
      z-index: 1;
      margin: -13px 0 0 -13px;
      width: 24px;
      height: 24px;
      left: 50%;
      top: 50%;
      content: "";
      border-radius: 24px;
      background-clip: padding-box;
      border-color: rgba(250, 190, 83, 0.25);
      border-width: 2px;
      border-style: solid;
      border-top-color: #fabe53;
      animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite; }
