.workspace-link {
  display: inline-block;
  white-space: pre;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
  color: #777777;
  height: 24px;
  padding: 0 5px;
  line-height: 22px;
  margin-right: 5px;
  transition: all 0.15s ease-out;
  text-decoration: none; }
  .workspace-link:hover {
    border-color: #999999;
    color: #222222;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05); }
