.project-jobs .project-jobs-header {
  display: flex;
  justify-content: space-between; }

.project-jobs .project-job-item {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden; }
  .project-jobs .project-job-item .color-border {
    display: flex;
    align-items: center;
    border-radius: 4px; }
    .project-jobs .project-job-item .color-border.finished {
      border-top: 3px solid #41b05e; }
    .project-jobs .project-job-item .color-border.warning, .project-jobs .project-job-item .color-border.waiting {
      border-top: 3px solid #fabe53; }
    .project-jobs .project-job-item .color-border.error {
      border-top: 3px solid #ed382a; }
    .project-jobs .project-job-item .color-border.canceled {
      border-top: 3px solid #222222; }
    .project-jobs .project-job-item .color-border.running {
      border-top: 3px solid #229ace; }
  .project-jobs .project-job-item .col-status {
    width: 85px;
    flex-shrink: 0;
    margin-left: 10px;
    margin-right: 12px;
    text-align: center; }
  .project-jobs .project-job-item .col-info {
    display: flex;
    align-items: center;
    margin: 17px 0 20px 12px;
    padding-right: 10px;
    border-right: 1px solid #cbccd0;
    flex-grow: 1;
    min-width: 10%; }
    .project-jobs .project-job-item .col-info img {
      flex-shrink: 0;
      max-width: 36px;
      max-height: 36px;
      margin-right: 8px; }
    .project-jobs .project-job-item .col-info .workspace-icon {
      margin-right: 8px;
      flex-shrink: 0; }
    .project-jobs .project-job-item .col-info .info {
      font-size: 12px;
      line-height: 16px;
      word-wrap: break-word;
      padding-left: 6px;
      padding-top: 4px;
      min-width: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .project-jobs .project-job-item .col-info .info a {
        text-decoration: none;
        text-transform: none;
        display: block;
        margin-top: -4px;
        margin-left: -6px;
        max-width: 100%; }
        .project-jobs .project-job-item .col-info .info a h3 {
          font-size: 16px;
          line-height: 21px;
          font-family: "Neue Hans Kendrick", sans-serif;
          border-radius: 6px;
          color: #222222;
          padding: 4px 6px;
          display: block;
          margin-bottom: 2px;
          word-break: break-word;
          word-wrap: break-word;
          max-width: 100%; }
        .project-jobs .project-job-item .col-info .info a:hover h3 {
          color: #fabe53;
          background: rgba(250, 190, 83, 0.1);
          transition: all 150ms ease-out; }
      .project-jobs .project-job-item .col-info .info p {
        margin: 0;
        max-width: 100%; }
        .project-jobs .project-job-item .col-info .info p.user-and-date {
          color: #999999; }
        .project-jobs .project-job-item .col-info .info p.workspace {
          margin-bottom: 3px; }
