.description-header {
  justify-content: space-between; }
  .description-header .form-enabled-buttons {
    display: flex;
    align-items: center; }
    .description-header .form-enabled-buttons .save-button {
      margin: 0 0 0 15px; }

.description-content {
  word-break: break-word;
  padding: 20px;
  font-size: 15px;
  line-height: 22px;
  white-space: pre-line;
  color: #222222; }
  .description-content p {
    margin: 0 0 5px; }
    .description-content p:last-child {
      margin-bottom: 0; }
  .description-content h1 {
    font-size: 24px;
    line-height: 28px;
    margin: 24px 0 12px; }
    .description-content h1:last-child {
      margin-bottom: 0; }
    .description-content h1:first-child {
      margin-top: 0; }
  .description-content h2 {
    font-size: 21px;
    line-height: 24px;
    margin: 21px 0 11px; }
    .description-content h2:last-child {
      margin-bottom: 0; }
    .description-content h2:first-child {
      margin-top: 0; }
  .description-content h3 {
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0 9px; }
    .description-content h3:last-child {
      margin-bottom: 0; }
    .description-content h3:first-child {
      margin-top: 0; }
  .description-content ul,
  .description-content ol {
    margin: 0 0 15px 0;
    padding: 0 0 0 35px; }
    .description-content ul:last-child,
    .description-content ol:last-child {
      margin-bottom: 0; }
  .description-content a {
    text-transform: none; }
  .description-content code {
    padding: 2px 5px;
    border-radius: 4px;
    background: #eaeef1; }
  .description-content pre {
    padding: 20px;
    border-radius: 6px;
    background-color: #f7f8f9;
    border: 1px solid #c4c4c4;
    line-height: normal; }
    .description-content pre code {
      padding: 0;
      border-radius: 0;
      background: transparent; }
  .description-content .expandable {
    position: relative;
    overflow: hidden;
    max-height: 150px; }
    .description-content .expandable .gradient {
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 35%);
      position: absolute;
      bottom: 0;
      left: 0; }
    .description-content .expandable.expanded {
      max-height: none;
      padding-bottom: 40px; }
    .description-content .expandable .show-more-less {
      position: absolute;
      bottom: 0px;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      width: 90px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-decoration: underline;
      cursor: pointer;
      text-align: center; }
      .description-content .expandable .show-more-less:hover {
        text-decoration: none; }
  .description-content .form-footer .tips {
    font-size: 11px;
    font-weight: bold;
    text-align: center; }
    .description-content .form-footer .tips span {
      margin: 0 4px; }
      .description-content .form-footer .tips span.headline {
        margin-left: 8px; }
      .description-content .form-footer .tips span.italics {
        font-weight: normal;
        font-style: italic; }
      .description-content .form-footer .tips span.strikethrough {
        font-weight: normal;
        text-decoration: line-through; }
      .description-content .form-footer .tips span.link-tip, .description-content .form-footer .tips span.escape-char, .description-content .form-footer .tips span.escape-sequence {
        font-weight: normal; }
  .description-content .form-footer .save-button {
    margin: 0 auto;
    display: block; }
