.status-element {
  display: flex;
  align-items: center;
  line-height: 18px;
  font-size: 16px; }
  .status-element span {
    display: block; }
  .status-element.left {
    text-align: left;
    float: left; }
  .status-element.right {
    text-align: right;
    float: right; }
  .status-element.center {
    text-align: center;
    flex-direction: column; }
  .status-element.canceled {
    color: #494a4a; }
    .status-element.canceled .right-icon,
    .status-element.canceled .left-icon,
    .status-element.canceled .center-icon {
      background-color: #494a4a; }
  .status-element.error {
    color: #ed382a; }
    .status-element.error .right-icon,
    .status-element.error .left-icon,
    .status-element.error .center-icon {
      background-color: #ed382a; }
  .status-element.finished {
    color: #41b05e; }
    .status-element.finished .right-icon,
    .status-element.finished .left-icon,
    .status-element.finished .center-icon {
      background-color: #41b05e; }
  .status-element.waiting, .status-element.warning {
    color: #fabe53; }
    .status-element.waiting .right-icon,
    .status-element.waiting .left-icon,
    .status-element.waiting .center-icon, .status-element.warning .right-icon,
    .status-element.warning .left-icon,
    .status-element.warning .center-icon {
      background-color: #fabe53; }
  .status-element.running {
    color: #229ace; }
    .status-element.running .right-icon,
    .status-element.running .left-icon,
    .status-element.running .center-icon {
      background-color: #229ace; }
  .status-element .col.right-icon {
    margin-left: 8px; }
  .status-element .col.left-icon {
    margin-right: 8px; }
  .status-element .col.center-icon {
    margin-bottom: 3px; }
  .status-element .col.right-icon, .status-element .col.left-icon, .status-element .col.center-icon {
    width: 28px;
    height: 28px;
    color: #ffffff;
    text-align: center;
    border-radius: 14px;
    font-size: 14px;
    padding-top: 5px;
    box-sizing: border-box; }
  .status-element .status-text {
    font-weight: bold;
    text-transform: capitalize; }
  .status-element.with-duration {
    font-size: 12px;
    line-height: 15px; }
    .status-element.with-duration .duration {
      font-size: 10px;
      line-height: 12px;
      color: #999999;
      white-space: pre; }
    .status-element.with-duration .right-icon {
      margin-left: 7px; }
    .status-element.with-duration .left-icon {
      margin-right: 7px; }
    .status-element.with-duration .right-icon,
    .status-element.with-duration .left-icon,
    .status-element.with-duration .center-icon {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      font-size: 10px;
      padding-top: 0px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center; }
    .status-element.with-duration .actual-duration {
      font-size: 10px;
      line-height: 12px;
      color: #999999; }
      .status-element.with-duration .actual-duration span {
        display: inline;
        white-space: pre; }
      .status-element.with-duration .actual-duration .running-seconds {
        font-weight: bold; }
    .status-element.with-duration.one-line-duration .actual-duration {
      white-space: pre; }

.status-link {
  border-radius: 6px;
  text-transform: none;
  display: inline-block;
  margin: -4px -8px;
  padding: 4px 8px;
  text-decoration: none; }
  .status-link:hover {
    background: rgba(250, 190, 83, 0.1);
    transition: all 150ms ease-out; }
  .status-link:after {
    display: table;
    content: "";
    clear: both; }
