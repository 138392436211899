.bool-toggle-button {
  width: 70px;
  height: 34px;
  padding: 2px;
  border-radius: 20px;
  font-size: 16px;
  background: #fabe53;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
  position: relative;
  border: 0; }
  .bool-toggle-button .switch {
    display: block;
    position: absolute;
    top: 3px;
    right: 3px;
    height: 28px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    width: 38px;
    transition: all 0.15s ease-out; }
    .bool-toggle-button .switch svg {
      margin: 6px 0 0 2px; }
  .bool-toggle-button.off {
    background: #e9eaea; }
    .bool-toggle-button.off .switch {
      right: 29px; }
  .bool-toggle-button.red-green.on {
    background-color: #41b05e; }
  .bool-toggle-button.red-green.off {
    background-color: #ed382a; }
  .bool-toggle-button:focus {
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
    outline: none; }
