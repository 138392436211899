.data-preview .data-preview-header {
  justify-content: space-between; }
  .data-preview .data-preview-header h3 {
    text-transform: none;
    font-size: 15px;
    font-weight: normal;
    max-width: 80%;
    word-break: break-word; }
  .data-preview .data-preview-header .data-download {
    flex-shrink: 0; }
  .data-preview .data-preview-header .buttons-block {
    display: flex; }
    .data-preview .data-preview-header .buttons-block .table-csv-switch {
      display: flex;
      align-items: center; }
      .data-preview .data-preview-header .buttons-block .table-csv-switch > label {
        margin: 0 10px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: bold;
        font-family: "Inter UI", sans-serif;
        color: #999999;
        letter-spacing: 0.05em;
        display: block;
        text-transform: uppercase; }
    .data-preview .data-preview-header .buttons-block .view-type-toggle {
      margin-right: 10px; }

.data-preview .data-preview-content {
  color: #c4c4c4;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 15px; }
  .data-preview .data-preview-content .file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 5px;
    border-bottom: 1px solid #c4c4c4;
    min-height: 56px; }
    .data-preview .data-preview-content .file-info .stats {
      letter-spacing: 1px;
      margin: 0;
      text-transform: uppercase; }
      .data-preview .data-preview-content .file-info .stats span {
        margin-right: 20px; }
        .data-preview .data-preview-content .file-info .stats span strong {
          color: #999999; }
    .data-preview .data-preview-content .file-info .source-conf {
      display: flex;
      align-items: center; }
      .data-preview .data-preview-content .file-info .source-conf .label {
        text-transform: uppercase;
        letter-spacing: 1px; }
      .data-preview .data-preview-content .file-info .source-conf .configuration-badge {
        display: flex;
        align-items: center;
        text-decoration: none;
        text-transform: none;
        margin-left: 10px;
        border-radius: 4px;
        padding: 0 12px 0 8px;
        height: 35px; }
        .data-preview .data-preview-content .file-info .source-conf .configuration-badge img {
          width: 32px;
          height: 32px;
          flex-shrink: 0; }
        .data-preview .data-preview-content .file-info .source-conf .configuration-badge:hover {
          background: rgba(250, 190, 83, 0.1); }
          .data-preview .data-preview-content .file-info .source-conf .configuration-badge:hover .right-part h4 {
            color: #fabe53; }
      .data-preview .data-preview-content .file-info .source-conf .right-part {
        margin-left: 8px; }
        .data-preview .data-preview-content .file-info .source-conf .right-part h4 {
          font-size: 13px;
          color: #222222; }
  .data-preview .data-preview-content .first-lines {
    padding: 20px 15px;
    margin: 20px 0px 10px;
    background: #f7f8f9;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    font-family: monospace;
    white-space: pre;
    box-sizing: border-box;
    overflow: auto; }
    .data-preview .data-preview-content .first-lines .line {
      position: relative;
      padding-left: 30px;
      font-size: 12px;
      line-height: 19px;
      min-height: 19px;
      font-weight: normal;
      color: #222222; }
      .data-preview .data-preview-content .first-lines .line span {
        color: #c4c4c4;
        position: absolute;
        left: 0; }
        .data-preview .data-preview-content .first-lines .line span.line-number {
          user-select: none; }
  .data-preview .data-preview-content .csv-table {
    margin-top: 30px;
    margin-bottom: -10px;
    padding-bottom: 10px;
    white-space: nowrap;
    display: block;
    overflow: auto; }
    .data-preview .data-preview-content .csv-table table {
      border-collapse: collapse;
      min-width: 100%; }
      .data-preview .data-preview-content .csv-table table thead th {
        font-family: "Neue Hans Kendrick", sans-serif;
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 1px;
        color: #999999;
        text-align: left;
        padding-right: 30px;
        padding-bottom: 0px; }
        .data-preview .data-preview-content .csv-table table thead th:first-child {
          padding-left: 10px; }
        .data-preview .data-preview-content .csv-table table thead th:last-child {
          padding-right: 10px; }
      .data-preview .data-preview-content .csv-table table tbody tr {
        border-bottom: 1px solid #c4c4c4; }
        .data-preview .data-preview-content .csv-table table tbody tr:last-child {
          border-bottom: 0; }
      .data-preview .data-preview-content .csv-table table tbody td {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #999999;
        padding: 13px 30px 13px 0; }
        .data-preview .data-preview-content .csv-table table tbody td:first-child {
          padding-left: 10px; }
        .data-preview .data-preview-content .csv-table table tbody td:last-child {
          padding-right: 10px; }
