.search-form {
  min-width: 200px;
  position: relative; }
  .search-form .search-field .search-button {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0.5; }
    .search-form .search-field .search-button:hover {
      opacity: 1; }
  .search-form .search-field input {
    border-radius: 4px;
    padding: 12px 40px 12px 15px;
    width: 100%;
    box-sizing: border-box; }
    .search-form .search-field input:focus + .search-button {
      opacity: 1; }
      .search-form .search-field input:focus + .search-button:hover {
        opacity: 0.5; }
