.dawg-link {
  text-transform: none;
  text-decoration: none;
  color: #222222;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 10px;
  line-height: 100%;
  background-color: #f7f9fb;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  transition: all 0.15s ease-out; }
  .dawg-link:hover {
    border-color: #999999;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
