.name-form {
  position: relative;
  width: 320px; }
  .name-form .tooltip-trigger {
    width: calc(100% - 75px);
    height: 44px;
    position: absolute;
    bottom: 0px;
    left: 15px;
    box-sizing: border-box; }
    .name-form .tooltip-trigger.edit {
      pointer-events: none; }
  .name-form .tooltip {
    padding: 10px; }
  .name-form .link-button {
    position: absolute;
    right: 14px;
    bottom: 13px;
    background: transparent;
    border: 0;
    font-size: 11px;
    line-height: 13px;
    color: #777777;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: bold;
    padding: 4px;
    display: block;
    height: 20px; }
    .name-form .link-button:focus {
      box-shadow: none;
      outline: none; }
    .name-form .link-button:hover {
      text-decoration: none; }
    .name-form .link-button.edit-mode {
      color: #fabe53; }
    .name-form .link-button:disabled {
      cursor: wait; }
      .name-form .link-button:disabled:hover {
        text-decoration: underline; }
  .name-form .close-icon-button {
    padding: 0;
    border: 0;
    background: transparent;
    color: #fabe53;
    transition: all 0.15s ease-out;
    position: absolute;
    bottom: 13px;
    left: -9px;
    height: 19px;
    width: 19px;
    margin: 0;
    border: 1px solid #fabe53;
    border-radius: 50%;
    background: #f8edda;
    font-size: 13px; }
    .name-form .close-icon-button:focus {
      box-shadow: none;
      outline: none; }
  .name-form .text-field.error input {
    border-color: #ed382a; }
  .name-form .text-field label {
    font-size: 11px;
    line-height: 15px;
    font-weight: bold;
    font-family: "Inter UI", sans-serif;
    letter-spacing: 1px;
    color: #999999;
    display: block;
    text-transform: uppercase; }
  .name-form .text-field input {
    width: 320px;
    box-sizing: border-box;
    color: #222222;
    font-size: 21px;
    font-family: "Neue Hans Kendrick", sans-serif;
    font-weight: bold;
    padding: 0 60px 0 15px;
    height: 44px;
    background: rgba(153, 153, 153, 0.2); }
    .name-form .text-field input:disabled {
      color: #222222;
      opacity: 1;
      -webkit-text-fill-color: #222222; }
  .name-form .text-field.edit-mode input {
    background: #f8edda;
    border-color: #fabe53;
    box-shadow: 0 1px 3px 0px rgba(254, 127, 102, 0.25); }
  .name-form .text-field.big input {
    font-size: 18px; }
  .name-form .text-field.medium input {
    font-size: 16px; }
  .name-form .text-field.small input {
    font-size: 14px; }
  .name-form .text-field.x-small input {
    font-size: 12px; }
  .name-form:hover .text-field:not(.edit-mode) input {
    background: rgba(119, 119, 119, 0.2);
    border-color: #999999; }

.name-not-editable {
  width: 320px;
  word-wrap: break-word; }
  .name-not-editable h2 {
    font-size: 21px;
    line-height: 24px;
    color: #222222;
    margin: 0; }
  .name-not-editable.big h2 {
    font-size: 18px; }
  .name-not-editable.medium h2 {
    font-size: 16px; }
  .name-not-editable.small h2 {
    font-size: 14px; }
  .name-not-editable.x-small h2 {
    font-size: 12px; }
  .name-not-editable .read-only-sign {
    white-space: pre;
    color: #777777;
    height: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 0.5px;
    display: inline-block;
    line-height: 24px;
    padding: 0 8px;
    vertical-align: middle; }
    .name-not-editable .read-only-sign .eye {
      margin-bottom: 1px; }
