// colors
$color_primary_lighter: #fac364;
$color_primary: #fabe53;
$color_primary_darker: #ffb433;
$color_grey_lighter: #777777;
$color_grey: #999999;
$color_grey_darker: #494a4a;
$color_warning_red: #ed382a;
$color_red: #cc4545;
$color_warning_orage: #fbab62;
$color_yellow: #fbb962;
$color_blue_lighter: #44a6d7;
$color_blue: #229ace;
$color_dark_blue: #01579b;
$color_purple: #7c2b73;
$color_green: #3aa545;
$color_white: #ffffff;
$color_black: #222222;
$color_link_header: #fddeac;
$color_border: #c4c4c4;
$color_lighting-yellow: #f8ac33;
$color_wasabi: #739943;
$color_medium-aquamarine: #5fc4be;
$color_persian-blue: #24299a;
$color_medium-purple: #8855db;
$color_contessa: #c07760;
$color_fire-engine-red: #d81515;
$color_cabaret: #ce4364;
$color_macaroni-and-cheese: #ffb681;
$color_broom: #edce27;
$color_fuego: #b4d538;
$color_gossamer: #3d9f99;
$color_silver-tree: #5fc487;
$color_nepal: #9fbac0;
$color_gumbo: #738f84;

$color_text: $color_grey_lighter;
$color_grey_secondary_menu: #f7f8f9;
$color_grey_site_background: #eaeef1;

$colors: (
  "primary": $color_primary,
  "blue": $color_dark_blue,
  "green": $color_green,
  "yellow": $color_yellow,
  "red": $color_red,
  "light-blue": $color_blue_lighter,
  "purple": $color_purple,
  "lighting-yellow": $color_lighting-yellow,
  "wasabi": $color_wasabi,
  "medium-aquamarine": $color_medium-aquamarine,
  "persian-blue": $color_persian-blue,
  "medium-purple": $color_medium-purple,
  "contessa": $color_contessa,
  "fire-engine-red": $color_fire-engine-red,
  "cabaret": $color_cabaret,
  "macaroni-and-cheese": $color_macaroni-and-cheese,
  "broom": $color_broom,
  "fuego": $color_fuego,
  "gossamer": $color_gossamer,
  "silver-tree": $color_silver-tree,
  "nepal": $color_nepal,
  "gumbo": $color_gumbo
);

// box shadows
$bs_smallest: 0 3px 3px rgba(0, 0, 0, 0.05);
$bs_small: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$bs_medium: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$bs_large: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
$bs_inset_large: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);

// fonts
$font_family_neue: "Neue Hans Kendrick", sans-serif;
$font_family_inter: "Inter UI", sans-serif;
$font_base_size: 16;

// border radiuses
$br_small: 4px;
$br_medium: 6px;
$br_large: 8px;

// transitions
$transition_default: all 0.15s ease-out;
$transition_long: all 0.5s ease;

// borders
$border_color_white_bg: #cbccd0;
$border_color_grey_light: #c4c4c4;
$border_color_grey: $color_grey;
$border_color_primary: $color_primary;
$border_color_warning_red: $color_warning_red;

$border_primary_thick: 2px solid $border_color_primary;
$border_grey_light_thin: 1px solid $border_color_grey_light;
$border_grey_light_thick: 2px solid $border_color_grey_light;

// viewport sizes breakpoints
$viewport_medium: 1280px;

// tables
$table_row_hover_bg: rgba(250, 190, 83, 0.05);
$table_bg: #f7f9fb;

// deprecated, try to change / remove it with code refactoring -->
$color_grey_light: #e9eaea;
$color_grey_light-text: #a2a4a6;
$color_grey-mid: #dee0e0;

$color_primary_lighter-text: #fde1b6;
$color_primary-hover: #fde1b6;
$color_primary-border: #f9b549;

$color_green: #41b05e;
$color_green-hover: #a3d7b0;
$color_amber: #ffc107;
$color_amber-hover: #ff6f00;
$color_red: #ed382a;
$color_red-hover: #f59d99;
$color_grey-hover: #efefef;
$color_purple: #8d47b9;

$schedule_form_bg_color: #e9edf0;
$form_grey_bg_color: #e9edf0;
$badge_grey_bg_color: #e9edf0;
$color_table-grey-row: #f7f8f8;

// deprecated box shadows, try to remove it from code
$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$box-shadow-stronger: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
$box-shadow-inset: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);

$transition_opacity: opacity 0.15s ease-in-out;

$primary-linear-gradient: linear-gradient(90deg, #f9b549 0%, #fac952 100%);
$primary-linear-gradient-hover: linear-gradient(90deg, #f7b24f 0%, #f9b549 100%);
