.colors-wrapper {
  position: relative; }
  .colors-wrapper .error-message {
    margin: 4px 0 0 17px;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: #ed382a;
    position: absolute; }

.colors-options-group {
  display: flex;
  flex-wrap: wrap; }
  .colors-options-group label {
    display: block;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.15s ease-out; }
    .colors-options-group label span {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      text-align: center;
      color: #ffffff;
      align-items: center;
      justify-content: center; }
      .colors-options-group label span .icon {
        display: block; }
      .colors-options-group label span.primary {
        background: #fabe53; }
      .colors-options-group label span.blue {
        background: #01579b; }
      .colors-options-group label span.green {
        background: #3aa545; }
      .colors-options-group label span.yellow {
        background: #fbb962; }
      .colors-options-group label span.red {
        background: #cc4545; }
      .colors-options-group label span.light-blue {
        background: #44a6d7; }
      .colors-options-group label span.purple {
        background: #7c2b73; }
      .colors-options-group label span.lighting-yellow {
        background: #f8ac33; }
      .colors-options-group label span.wasabi {
        background: #739943; }
      .colors-options-group label span.medium-aquamarine {
        background: #5fc4be; }
      .colors-options-group label span.persian-blue {
        background: #24299a; }
      .colors-options-group label span.medium-purple {
        background: #8855db; }
      .colors-options-group label span.contessa {
        background: #c07760; }
      .colors-options-group label span.fire-engine-red {
        background: #d81515; }
      .colors-options-group label span.cabaret {
        background: #ce4364; }
      .colors-options-group label span.macaroni-and-cheese {
        background: #ffb681; }
      .colors-options-group label span.broom {
        background: #edce27; }
      .colors-options-group label span.fuego {
        background: #b4d538; }
      .colors-options-group label span.gossamer {
        background: #3d9f99; }
      .colors-options-group label span.silver-tree {
        background: #5fc487; }
      .colors-options-group label span.nepal {
        background: #9fbac0; }
      .colors-options-group label span.gumbo {
        background: #738f84; }
  .colors-options-group .color-opt {
    display: none; }
    .colors-options-group .color-opt:checked + label {
      opacity: 1; }
  .colors-options-group.active .marked {
    opacity: 1; }
  .colors-options-group.active label {
    opacity: 0.5; }
